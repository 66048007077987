import { japaneseList } from "../Resources/japaneseList";

export const ERRORS = {
  INVALID_PASSWORD: {
    MESSAGEJP: japaneseList.Utils.Error.j001,
    EMPTY: {
      MESSAGE: "Password cannot be empty",
    },
    LEGNTHDEFAULT: {
      CODE: "InvalidParameterException",
      MESSAGE: "Member must have length greater than or equal to 6",
    },
    POLICY: {
      CODE: "InvalidPasswordException",
      UPPERCASE: {
        MESSAGE: "Password must have uppercase characters",
        MESSAGEJP: japaneseList.Utils.Error.j002,
      },
      LOWERCASE: {
        MESSAGE: "Password must have lowercase characters",
        MESSAGEJP: japaneseList.Utils.Error.j002,
      },
      LENGTH: {
        MESSAGE: "Password not long enough",
        MESSAGEJP: japaneseList.Utils.Error.j003,
      },
    },
  },
  INVALID_PHONE_NUMBER: {
    MESSAGEJP: japaneseList.Utils.Error.j004,
  },
  USER_EXISTS:{
    CODE: "UsernameExistsException",
    MESSAGEJP: japaneseList.Utils.Error.j005,
  },

  SIGNUP: {
    FAMILYNAME: {
      FULLWIDTH: {
        MESSAGEJP: japaneseList.pages.auth.SignUpPage.validateInput.j014,
      },
      YAMATO: {
        MESSAGEJP: japaneseList.pages.auth.SignUpPage.validateInput.j009,
      },
    },
    GIVENNAME: {
      FULLWIDTH: {
        MESSAGEJP: japaneseList.pages.auth.SignUpPage.validateInput.j015,
      },
      YAMATO: {
        MESSAGEJP: japaneseList.pages.auth.SignUpPage.validateInput.j010,
      },
    },
    FURIGANAFAMILY: {
      FULLWIDTH: {
        MESSAGEJP: japaneseList.pages.auth.SignUpPage.validateInput.j016,
      },
      KATAKANA: {
        MESSAGEJP: japaneseList.pages.auth.SignUpPage.validateInput.j018,
      },
    },
    FURIGANAGIVEN: {
      FULLWIDTH: {
        MESSAGEJP: japaneseList.pages.auth.SignUpPage.validateInput.j017,
      },
      KATAKANA: {
        MESSAGEJP: japaneseList.pages.auth.SignUpPage.validateInput.j019,
      },
    },
    ADDRESSCITY: {
      YAMATO: {
        MESSAGEJP: japaneseList.pages.auth.SignUpPage.validateInput.j020,
      },
    },
    ADDRESSSTREETNUMBER: {
      YAMATO: {
        MESSAGEJP: japaneseList.pages.auth.SignUpPage.validateInput.j021,
      },
    },
    ADDRESSBUILDING: {
      YAMATO: {
        MESSAGEJP: japaneseList.pages.auth.SignUpPage.validateInput.j022,
      },
    },
    POSTALCODE: {
      DIGITS: {
        MESSAGEJP: japaneseList.pages.auth.SignUpPage.validateInput.j023,
      },
    },
  },
};
