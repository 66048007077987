import React from "react";
import styled from "styled-components";
import Heading1 from "../../atoms/headings/Heading1";
import FontP from "../../atoms/fonts/FontP";
import { SearchFromHospitalHistoriesCard } from "./SearchFromHospitalHistoriesCard";
import LinkToNoUnder from "../../atoms/links/LinkToNoUnder";
import { japaneseList } from "../../../Resources/japaneseList";
import { FACILITY_TYPE } from "../../../Utils/Constant";

interface Props {
  appointments: Appointment[];
}

interface Appointment {
  hospitalId: string;
  status: string;
  hospitalName: string;
  from: string;
  to: string;
  canCreateAppointment: boolean;
}

const renderResource = japaneseList.organisms.AppointmentHistoryPage.render;

const Heading = styled(Heading1)`
  padding: 7px 10px;
`;

const HeadingText = styled(FontP)`
  height: 26px;
  line-height: 25px;
  color: #ffffff;
  font-weight: 700;
`;

export const SearchFromHospitalHistoriesDetail = (props: Props) => {
  const { appointments } = props;
  const searchParams = new URLSearchParams(window.location.search.substr(1));
  const facilityType = searchParams.get("type");

  return (
    <div>
      {appointments && appointments.length > 0 && (
        <React.Fragment>
          <Heading>
            <HeadingText>
              {facilityType === FACILITY_TYPE.HOSPITAL && renderResource.j008}
              {facilityType === FACILITY_TYPE.PHARMACY && renderResource.j009}
            </HeadingText>
          </Heading>
          {appointments.map((item, index) => {
            const url = "/hospital/" + item.hospitalId;
            return (
              <React.Fragment key={index}>
                {item.canCreateAppointment && (
                  <LinkToNoUnder id="appointment-history-list" to={url}>
                    <SearchFromHospitalHistoriesCard appointment={item} />
                  </LinkToNoUnder>
                )}
                {!item.canCreateAppointment && (
                  <SearchFromHospitalHistoriesCard appointment={item} />
                )}
              </React.Fragment>
            );
          })}
        </React.Fragment>
      )}
    </div>
  );
};
