import React from "react";
import styled from "styled-components";
import Heading2LnlnOffice from "../../atoms/headings/Heading2LnlnOffice";
import FontPBold from "../../atoms/fonts/FontPBold";
import FontP from "../../atoms/fonts/FontP";
import { japaneseList } from "../../../Resources/japaneseList";

const Contents = styled.div`
  padding: 20px 14px;
  padding-bottom: 10px;
`;

const ContentSet = styled.div`
  :not(:last-child) {
    margin-bottom: 20px;
  }
`;

const Title = styled(FontPBold)`
  margin-bottom: 5px;
  color: ${(p) => p.theme.lnlnOffice.darkBlue2};
`;

const Content = styled(FontP)`
  font-size: 14px;
`;

export const EnterpriseContractPlanList = ({
  enterpriseName,
  contractPlans = [],
  employeeNumber,
}) => {
  const renderResource =
    japaneseList.organisms.enterprise.EnterpriseContractPlanList;
  return (
    <React.Fragment>
      <Heading2LnlnOffice>{renderResource.render.j001}</Heading2LnlnOffice>
      <Contents>
        <ContentSet>
          <Title>{renderResource.render.j002}</Title>
          <Content>{enterpriseName}</Content>
        </ContentSet>
        <ContentSet>
          <Title>{renderResource.render.j003}</Title>
          {contractPlans.length ? (
            contractPlans.map((contractPlan, index) => (
              <Content key={index}>{contractPlan.contractPlanName}</Content>
            ))
          ) : (
            <Content>{renderResource.render.j004}</Content>
          )}
        </ContentSet>
        <ContentSet>
          <Title>{renderResource.render.j005}</Title>
          <Content>
            {employeeNumber ? employeeNumber : renderResource.render.j006}
          </Content>
        </ContentSet>
      </Contents>
    </React.Fragment>
  );
};
