import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { isAfter, addHours } from "date-fns";
import ButtonLoadMore from "../atoms/buttons/ButtonLoadMore";
import Heading1 from "../atoms/headings/Heading1";
import { NotificationList } from "../organisms/NotificationList/NotificationList";
import AuthenticatedTemplate from "../templates/AuthenticatedTemplate";
import GraphQLClient from "../../Utils/GraphQLClient";
import { ListPersonalNotifications } from "../../Utils/GraphQLQueries";
import { formatTime, formatPeriod } from "../../Utils/Utility";
import { japaneseList } from "../../Resources/japaneseList";
import Titles from "../../Resources/Titles";
import {
  APPOINTMENT_STATUS,
  INTERVIEW_SHEET_ANSWER_STATUS,
  NOTIFICATION_TYPE,
  CONSENT_STATUS,
} from "../../Utils/Constant";
import Guard from "../../Utils/Guard";

const Button = styled(ButtonLoadMore)`
  float: right;
`;

const EmptyAnnounce = styled.div`
  text-align: center;
  width: 100%;
  padding: 50px 0;
`;

const limit = 5;

const CANCELED_STATUS = [
  APPOINTMENT_STATUS.CANCELED_DOCTOR,
  APPOINTMENT_STATUS.CANCELED_PATIENT_PREVIOUS_DAY,
  APPOINTMENT_STATUS.CANCELED_PATIENT_FROM_TODAY,
];

export const NotificationListPage = Guard((props) => {
  const [personalNotifications, setPersonalNotifications] = useState([]);
  const [totalLimit, setTotalLimit] = useState(limit);
  const [total, setTotal] = useState(0);
  const [noNotificationMessage, setNoNotificationMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getNotificationList();
  }, []);

  const getNotificationList = async () => {
    try {
      const { data, errors } = await GraphQLClient.query({
        query: ListPersonalNotifications,
      });

      if (errors) {
        console.error(
          "ListPersonalNotifications errors:",
          JSON.stringify(errors)
        );
      }
      if (data && data.personalNotifications) {
        if (data.personalNotifications.items.length > 0) {
          setPersonalNotifications(data.personalNotifications.items);
          setTotal(data.personalNotifications.totalCount);
        } else {
          setNoNotificationMessage(
            japaneseList.pages.NotificationListPage.getNotificationList.j001
          );
        }
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onLoadMore = () => {
    setTotalLimit(totalLimit + limit);
  };

  const linkToAppointmentDetailPage = (item) => {
    const { appointmentKey, notificationId, notificationType, isUnread } = item;
    props.history.push({
      pathname: `/appointment/${appointmentKey}`,
      state: {
        isRead: true,
        notificationId: notificationId,
        notificationType: notificationType,
        isUnreadNotification: isUnread,
      },
    });
  };

  const linkToInterviewSheetAnswerUpdatePage = (item) => {
    const { appointmentKey, notificationId, notificationType, isUnread } = item;
    props.history.push({
      pathname: `/interview/update/${appointmentKey}`,
      state: {
        fromNotificationList: true,
        isRead: true,
        notificationId: notificationId,
        notificationType: notificationType,
        isUnreadNotification: isUnread,
      },
    });
  };

  const isNeededToEditInterviewSheetAnswer = (item) => {
    const now = new Date();
    const isCreatedByDoctor =
      item.notificationType === NOTIFICATION_TYPE.CREATED_BY_DOCTOR;
    const isOver1HourBeforeAppointmentFrom = isAfter(
      item.appointmentFrom,
      addHours(now, 1).toISOString()
    );
    const isOkAppointmentStatus =
      item.appointmentStatus === APPOINTMENT_STATUS.UNAPPROVED ||
      item.appointmentStatus === APPOINTMENT_STATUS.BEFORE_EXAM;
    const isUnanswered =
      item.interviewSheetAnswerStatus ===
      INTERVIEW_SHEET_ANSWER_STATUS.UNANSWERED;

    return (
      isCreatedByDoctor &&
      isOver1HourBeforeAppointmentFrom &&
      isOkAppointmentStatus &&
      isUnanswered
    );
  };
  const shouldCancelConsentRegistration = ({
    appointmentStatus,
    canCancelConsent,
    consentStatus,
  }) => {
    const isCanceledByDoctor =
      appointmentStatus === APPOINTMENT_STATUS.CANCELED_DOCTOR;
    const isRegistered =
      consentStatus === CONSENT_STATUS.REGISTERED ||
      consentStatus === CONSENT_STATUS.REGISTERING;
    return isCanceledByDoctor && isRegistered && Boolean(canCancelConsent);
  };

  const loadMore =
    total <= totalLimit ? (
      ""
    ) : (
      <Button onClick={onLoadMore}>
        {japaneseList.pages.NotificationListPage.render.loadMore.Button.j001}
      </Button>
    );

  const notificationList = personalNotifications
    .slice(0, totalLimit)
    .map((item, index) => {
      const appoDatetime = formatPeriod(
        item.appointmentFrom,
        item.appointmentTo
      );
      const notificationDatetime = formatTime(item.notificationDatetime);
      const isCanceled = CANCELED_STATUS.includes(item.appointmentStatus);
      const isShowInterviewSheetAnswerMsg =
        isNeededToEditInterviewSheetAnswer(item);
      const isShowFileDeletedMsg = !item.existsValidAttachment;
      const linkTo = isShowInterviewSheetAnswerMsg
        ? () => linkToInterviewSheetAnswerUpdatePage(item)
        : () => linkToAppointmentDetailPage(item);

      return (
        <NotificationList
          key={`item-${index}`}
          notificationType={item.notificationType}
          appoDatetime={appoDatetime}
          hospitalName={item.hospitalName}
          menuDisplayName={item.menuDisplayName}
          notificationDatetime={notificationDatetime}
          isUnread={item.isUnread}
          linkTo={linkTo}
          isCanceled={isCanceled}
          isShowInterviewSheetAnswerMsg={isShowInterviewSheetAnswerMsg}
          isShowFileDeletedMsg={isShowFileDeletedMsg}
          shouldCancelConsentRegistration={shouldCancelConsentRegistration(
            item
          )}
        />
      );
    });

  const main = (
    <React.Fragment>
      <Heading1>
        {
          japaneseList.pages.NotificationListPage.render.main.Fragment
            .PrimaryHeading.j001
        }
      </Heading1>
      {noNotificationMessage && (
        <EmptyAnnounce>{noNotificationMessage}</EmptyAnnounce>
      )}
      {notificationList}
      {loadMore}
    </React.Fragment>
  );

  return (
    <AuthenticatedTemplate
      title={Titles.notificationList}
      main={main}
      isLoading={isLoading}
    />
  );
});
