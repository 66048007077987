import styled from "styled-components";

const HeadingLeftDecoration = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 4px;
  line-height: 1.2;
  font-weight: bold;
  font-size: ${(p) => p.theme.font.size.l};
  color: ${(p) => p.theme.font.color.default};
  &::before {
    content: "";
    display: block;
    height: ${(p) => p.theme.font.size.l};
    width: 6px;
    margin-right: 7px;
    background-color: ${(p) => p.theme.color.primary};
    border-radius: 6px;
  }
`;

export default HeadingLeftDecoration;
