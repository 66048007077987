import uaParser from "ua-parser-js";
import { isLnlnApp } from "../Utils/checkLnln";

export const device = {
  mobile: `(max-width: 725px)`,
  tb: `(min-width: 768px)`,
  pc: `(min-width: 1024px)`,
};

export const DeviceOSName = {
  Android: "Android",
  iPhone: "iPhone",
  Windows: "Windows",
  Mac: "Mac",
  iPad: "iPad",
  Others: "Others",
};

export const deviceOS = function () {
  const userAgent = window.navigator.userAgent;
  let current = uaParser(userAgent);
  if (current) {
    if (current.os.name === "Android") {
      return DeviceOSName.Android;
    } else if (current.os.name === "iOS") {
      if (current.device.model === "iPhone") {
        return DeviceOSName.iPhone;
      }
      if (current.device.model === "iPad") {
        return DeviceOSName.iPad;
      }
    } else if (current.os.name === "Windows") {
      return DeviceOSName.Windows;
    } else if (current.os.name === "Mac OS") {
      return DeviceOSName.Mac;
    }
  }
  return DeviceOSName.Others;
};

export const isWebView = () => {
  const WEB_VIEW_BROWSERS = ["Chrome WebView", "WebKit"];
  // UserAgentで判定
  const userAgent = window.navigator.userAgent;
  const browserName = uaParser(userAgent)?.browser?.name;
  const isWebViewBrowser = WEB_VIEW_BROWSERS.includes(browserName);

  // UserAgentで判定できない環境がある可能性を考慮し、補助的にルナルナアプリ内で開かれているかを使用する
  return isWebViewBrowser || isLnlnApp();
};
