import React, { Component } from "react";
import Image from "../atoms/others/Image";
import styled from "styled-components";
import startOfMonth from "date-fns/start_of_month";
import endOfMonth from "date-fns/end_of_month";
import parse from "date-fns/parse";
import CvButtonPadding from "../molecules/buttons/CvButtonPadding";
import ListPlanCard from "./ListPlanCard";
import ListAppointment from "./ListAppointment";
import { LnlnOfficeBanner } from "../molecules/lnlnOffice/LnlnOfficeBanner";
import ListHospital from "./ListHospital";
import SearchHospital from "./SearchHospital";
import UsingFlow from "./UsingFlow";
import GraphQLClient from "../../Utils/GraphQLClient";
import {
  Account,
  ListAppointmentsOfPatientWithConsentByStatus,
  GetAnnouncement,
  ListAppointmentsWithConsentByStatus,
} from "../../Utils/GraphQLQueries";
import { isLnln } from "../../Utils/checkLnln";
import HomePageCheck from "./HomePageCheck";
import Benefits from "./Benefits";
import { withRouter } from "react-router-dom";
import { isIndustrialPhysicianByAccount } from "../../Utils/IndustrialPhysician";
import { japaneseList } from "../../Resources/japaneseList";
import Announcement from "./Announcement";

import Img1Path from "../../images/carada/01.png";
import Img1PathLnln from "../../images/lnln/01.png";
import Img2Path from "../../images/carada/02.png";
import FontBase from "../atoms/fonts/FontBase";
import FontP from "../atoms/fonts/FontP";
const img1 = isLnln() ? Img1PathLnln : Img1Path;
const img2 = Img2Path;

const PaddingBottomBox = styled.div`
  padding-bottom: 16px;
`;

const MarginTop = styled.div`
  margin-top: 15px;
`;

const ButtonSignUp = styled(CvButtonPadding)`
  padding-top: 20px;
`;

const NotLoggedInLnlnOfficeWrapper = styled.div`
  padding: 0px 30px 20px;
`;
const LoggedInLnlnOfficeWrapper = styled.div`
  padding: 0px 15px 16px;
`;

const SiteDescriptionWrapper = styled.div`
  padding: 0px 30px;
  text-align: center;
`;
const SiteDescriptionTitleFont = styled(FontBase)`
  color: ${(p) => p.theme.font.color.therd};
  font-weight: bold;
  margin-bottom: 10px;
`;

const SiteDescriptionContentFont = styled(FontP)`
  font-size: ${(p) => p.theme.font.size.s};
  color: ${(p) => p.theme.font.color.darkGray};
`;

// TODO: お知らせを追加するときのためにコメントアウト
// const HomeNotificationArea = styled.div`
//   background-color: #fff6f6;
//   border: 1px solid #f26666;
//   border-radius: 3px;
//   box-sizing: border-box;
//   margin: 0px 15px 20px 15px;
//   padding: 10px;
// `;
// const HomeNotificationTitle = styled.div`
//   font-weight: bold;
//   margin-bottom: 10px;
//   color: #f26666;
//   font-size: ${(p) => p.theme.font.size.m};
// `;
// const HomeNotificationBody = styled.div`
//   font-size: ${(p) => p.theme.font.size.ss};
// `;
// const HomeNotificationSubBody = styled.div`
//   color: #999999;
//   font-size: ${(p) => p.theme.font.size.sss};
// `;

// const HomeNotificationArea = styled.div`
//   background-color: #fff6f6;
//   border: 2px solid #f26666;
//   box-sizing: border-box;
//   margin: 25px 15px 25px 15px;
//   padding: 5px;
// `;
// const HomeNotificationTitle = styled.div`
//   color: #f26666;
//   font-size: ${(p) => p.theme.font.size.m};
// `;
// const HomeNotificationBody = styled.div`
//   margin-bottom: 5px;
//   font-size: ${(p) => p.theme.font.size.ss};
//   white-space: pre-wrap;
//   word-wrap: break-word;
// `;
// const HomeNotificationBodyBold = styled.div`
//   font-weight: bold;
//   font-size: ${(p) => p.theme.font.size.ss};
// `;
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: {},
      postalCode: true,
      organizationId: null,
      insuranceCard: {
        shouldUpload: false,
        hasAppointmentSupportMyNumberCard: false,
      },
      displayAnnouncement: false,
      announcementInfo: null,
    };
  }

  async componentDidMount() {
    const { data: announcementData } = await GraphQLClient.query({
      query: GetAnnouncement,
    });
    if (announcementData.announcement) {
      this.setState({
        displayAnnouncement: true,
        announcementInfo: announcementData.announcement,
      });
    }

    const { data } = await GraphQLClient.query({ query: Account });
    if (!data.account) return;
    const account = data.account;
    const postalCode = account.address
      ? account.address.postalCode
      : this.state.postalCode;
    const organizationId = account.organizationId
      ? account.organizationId
      : this.state.organizationId;

    const appointmentsAllStatus = await (async () => {
      if (account?.enterprise?.contractPlans?.length > 0) {
        const { data, errors } = await GraphQLClient.query({
          query: ListAppointmentsWithConsentByStatus,
        });
        if (errors) return;
        const appointments = data.appointmentsWithConsent.items;
        return appointments;
      }
      return [];
    })();
    // Get appointments
    let selectedDate = new Date();
    const monthStart = startOfMonth(selectedDate);
    const monthEnd = endOfMonth(monthStart);
    const patientParams = {
      start: monthStart,
      end: monthEnd,
      status: ["beforeExam", "unapproved"],
    };
    const { data: appointmentsPatient, errors } = await GraphQLClient.query({
      query: ListAppointmentsOfPatientWithConsentByStatus,
      variables: patientParams,
    });
    if (errors) return;
    const insuranceAppointments =
      appointmentsPatient.appointmentsWithConsent.items.filter(
        (app) =>
          app.menu &&
          !(
            app.menu.medicalMethod === "offline" &&
            app.menu.medicalMenu === "examination"
          ) &&
          app.menu.supportsInsuranceTreatment &&
          parse(app.from) <= monthEnd &&
          parse(app.from) >= monthStart
      );
    const hasInsuranceAppointments = insuranceAppointments.length > 0;
    const hasInsuranceCard =
      account.insuranceCards[account.insuranceCards.length - 1];
    const shouldUploadInsuranceCard =
      hasInsuranceAppointments && !hasInsuranceCard;

    const hasAppointmentSupportMyNumberCard = insuranceAppointments.every(
      (appointment) => {
        return appointment.isSupportMyNumberInsuranceCard;
      }
    );

    this.setState({
      account: account,
      postalCode: postalCode,
      organizationId: organizationId,
      insuranceCard: {
        shouldUpload: shouldUploadInsuranceCard,
        hasAppointmentSupportMyNumberCard,
      },
      appointmentsAllStatus: appointmentsAllStatus,
    });
  }

  goToSignUpPage = () => {
    this.props.history.push(`/auth/signup`);
  };

  render() {
    const renderResource = japaneseList.organisms.Home.render;
    const { hospitals, appointments, login } = this.props;
    const { account, appointmentsAllStatus } = this.state;
    // const NotificationTermOfService = (
    //   <HomeNotificationArea>
    //     <HomeNotificationTitle>
    //       {renderResource.Notification.Title.j001}
    //     </HomeNotificationTitle>
    //     <HomeNotificationBody>
    //       {renderResource.Notification.Body.j001}
    //       <br />
    //       <br />
    //       {renderResource.Notification.Body.j002}
    //       <br />
    //       {renderResource.Notification.Body.j003}
    //       <br />
    //       <br />
    //       {renderResource.Notification.Body.j004}
    //       <br />
    //       {renderResource.Notification.Body.j005}
    //       <br />
    //       {renderResource.Notification.Body.j006}
    //       <br />
    //       <br />
    //       {renderResource.Notification.SubBody.j001}
    //       <LinkTo
    //         to={"/terms-of-service"}
    //         target={"_blank"}
    //         style={{ color: "inherit" }}
    //       >
    //         {renderResource.Notification.SubBody.PrimaryLinkTo.j001}
    //       </LinkTo>
    //       {renderResource.Notification.SubBody.j002}
    //     </HomeNotificationBody>
    //   </HomeNotificationArea>
    // );

    return login ? (
      <div>
        {this.state.displayAnnouncement && (
          <Announcement announcementInfo={this.state.announcementInfo} />
        )}
        <HomePageCheck
          postalCode={this.state.postalCode}
          organizationId={this.state.organizationId}
          insuranceCard={this.state.insuranceCard}
        />
        {/* 次回お知らせ追加時にスムーズにできるように削除せずコメントアウト */}
        {/* <HomeNotificationArea>
          <HomeNotificationTitle>
            {renderResource.Notification.Title.j001}
          </HomeNotificationTitle>
          <HomeNotificationBody>
            <br />
            {renderResource.Notification.Body.j001}
            {renderResource.Notification.Body.j002}
          </HomeNotificationBody>
          <HomeNotificationSubBody>
            {renderResource.Notification.SubBody.j001}
            <br />
            {renderResource.Notification.SubBody.j002}
          </HomeNotificationSubBody>
        </HomeNotificationArea> */}

        {/* {NotificationTermOfService} */}
        {isLnln() && (
          <ListPlanCard
            account={account}
            appointmentsAllStatus={appointmentsAllStatus}
          />
        )}
        <ListAppointment appointments={appointments} />

        {isLnln() && account?.enterprise?.contractPlans?.length > 0 ? (
          ""
        ) : this.state.organizationId ? (
          <ListHospital
            hospitals={hospitals}
            organizationId={this.state.organizationId}
          />
        ) : (
          <SearchHospital login={login} />
        )}

        {!isIndustrialPhysicianByAccount(account) && (
          <>
            <PaddingBottomBox>
              <UsingFlow login={login} />
            </PaddingBottomBox>
            {isLnln() &&
              account?.enterprise?.contractPlans?.length > 0 &&
              (this.state.organizationId ? (
                <ListHospital
                  hospitals={hospitals}
                  organizationId={this.state.organizationId}
                />
              ) : (
                <SearchHospital login={login} isUpdateHeading={true} />
              ))}
            {isLnln() && (
              <LoggedInLnlnOfficeWrapper>
                <LnlnOfficeBanner GAid="top-to-lnln-office-registration-link" />
              </LoggedInLnlnOfficeWrapper>
            )}
          </>
        )}
      </div>
    ) : (
      <div>
        <PaddingBottomBox>
          <Image src={img1} />
        </PaddingBottomBox>
        {this.state.displayAnnouncement && (
          <Announcement announcementInfo={this.state.announcementInfo} />
        )}
        {/* 次回お知らせ追加時にスムーズにできるように削除せずコメントアウト */}
        {/* <HomeNotificationArea>
          <HomeNotificationTitle>
            {renderResource.Notification.Title.j001}
          </HomeNotificationTitle>
          <HomeNotificationBody>
            <br />
            {renderResource.Notification.Body.j001}
            {renderResource.Notification.Body.j002}
          </HomeNotificationBody>
          <HomeNotificationSubBody>
            {renderResource.Notification.SubBody.j001}
            <br />
            {renderResource.Notification.SubBody.j002}
          </HomeNotificationSubBody>
        </HomeNotificationArea> */}
        {/* {NotificationTermOfService} */}
        {isLnln() ? (
          <SiteDescriptionWrapper>
            <SiteDescriptionTitleFont>
              {renderResource.div.SiteDescription.Lnln.Title.j001}
            </SiteDescriptionTitleFont>
            <SiteDescriptionContentFont>
              {renderResource.div.SiteDescription.Lnln.Content.j001}
            </SiteDescriptionContentFont>
            <SiteDescriptionContentFont>
              {renderResource.div.SiteDescription.Lnln.Content.j002}
            </SiteDescriptionContentFont>
          </SiteDescriptionWrapper>
        ) : (
          <MarginTop>
            <Image src={img2} />
          </MarginTop>
        )}

        <ButtonSignUp onClick={this.goToSignUpPage}>
          {renderResource.div.ButtonSignUp.j001}
        </ButtonSignUp>
        {isLnln() && (
          <NotLoggedInLnlnOfficeWrapper>
            <LnlnOfficeBanner GAid="top-to-lnln-office-registration-link" />
          </NotLoggedInLnlnOfficeWrapper>
        )}
        <SearchHospital />
        <UsingFlow />
        <CvButtonPadding onClick={this.goToSignUpPage}>
          {renderResource.div.ButtonPadding.j001}
        </CvButtonPadding>
        <Benefits />
        <CvButtonPadding onClick={this.goToSignUpPage}>
          {renderResource.div.ButtonPadding.j002}
        </CvButtonPadding>
      </div>
    );
  }
}

export default withRouter(Home);
