import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import CardBody from "../molecules/PlanCardBody";
import FontPSmallCenter from "../atoms/fonts/FontPSmallCenter";
import { MergedPlan } from "./ListPlanCard";
import { APPOINTMENT_STATUS } from "../../Utils/Constant";
import { japaneseList } from "../../Resources/japaneseList";

const renderResource = japaneseList.organisms.planCard;

interface PlanCardProps {
  mergedPlan: MergedPlan;
  planAppointments: any[];
}

const Container = styled.div`
  padding: 10px 15px;
`;

const Card = styled.div`
  border: 1px solid ${(p) => p.theme.lnlnOffice.darkBlue1};
  border-radius: 6px;
  overflow: hidden;
`;

const CardHeader = styled.div`
  background: ${(p) => p.theme.lnlnOffice.darkBlue1};
  padding: 5px;
`;

const FontPSmallCenterWhite = styled(FontPSmallCenter)`
  color: ${(p) => p.theme.font.color.white};
  font-weight: 600;
`;

const PlanCard: React.FC<PlanCardProps> = ({
  mergedPlan,
  planAppointments,
}) => {
  const history = useHistory();
  const now = new Date();

  const hasFuturePlanAppointmentsBeforeExam = planAppointments.some(
    (appointment) =>
      appointment.status === APPOINTMENT_STATUS.BEFORE_EXAM &&
      new Date(appointment.to) >= now
  );

  const hasPlanAppointmentsUnapproved = planAppointments.some(
    (appointment) => appointment.status === APPOINTMENT_STATUS.UNAPPROVED
  );

  const hasPlanAppointment =
    hasFuturePlanAppointmentsBeforeExam || hasPlanAppointmentsUnapproved;

  const appointmentHistoryStatus = [
    APPOINTMENT_STATUS.EXAM_COMPLETED,
    APPOINTMENT_STATUS.PAYMENT_COMPLETED,
    APPOINTMENT_STATUS.CANCELED_DOCTOR,
    APPOINTMENT_STATUS.CANCELED_PATIENT_PREVIOUS_DAY,
    APPOINTMENT_STATUS.CANCELED_PATIENT_FROM_TODAY,
    APPOINTMENT_STATUS.CANCELED_BEFORE_APPROVING,
  ];

  const hasPlanAppointmentHistory = planAppointments.some((appointment) =>
    appointmentHistoryStatus.includes(appointment.status)
  );

  const navigateToHospitalSearchFromHistory = () => {
    history.push(`/search/histories?type=hospital`);
  };

  const navigateToHospitalSearchByPlan = () => {
    history.push(`/search/plan`, {
      planId: mergedPlan.planId,
    });
  };

  return (
    <Container>
      <Card>
        <CardHeader>
          <FontPSmallCenterWhite>
            {renderResource.PlanCardHeader + mergedPlan.planName}
          </FontPSmallCenterWhite>
        </CardHeader>
        <CardBody
          hasPlanAppointment={hasPlanAppointment}
          hasPlanAppointmentHistory={hasPlanAppointmentHistory}
          planName={mergedPlan.planName}
          planId={mergedPlan.planId}
          navigateToHospitalSearchFromHistory={
            navigateToHospitalSearchFromHistory
          }
          navigateToHospitalSearchByPlan={navigateToHospitalSearchByPlan}
        />
      </Card>
    </Container>
  );
};

export default PlanCard;
