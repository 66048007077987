import styled from "styled-components";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import Card from "../../molecules/Card";
import FontPBold from "../../atoms/fonts/FontPBold";
import FontPSmall from "../../atoms/fonts/FontPSmall";
import { FACILITY_TYPE } from "../../../Utils/Constant";
import { formatPeriod } from "../../../Utils/Utility";
import { japaneseList } from "../../../Resources/japaneseList";
import IconMenuTag from "../../atoms/icons/IconMenuTag";

interface Props {
  appointment: Appointment;
}

interface Appointment {
  hospitalName: string;
  from: string;
  to: string;
  canCreateAppointment: boolean;
  menu?: {
    displayName: string;
  };
  plan?: {
    planName: string;
    planId: string;
  };
}

const renderResource = japaneseList.organisms.AppointmentHistoryPage.render;

const WarningMessage = styled(FontPSmall)`
  color: #ff4d4d;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;
  height: 21px;
  line-height: 21px;
`;

const FontPBoldCustom = styled(FontPBold)`
  color: ${(p) => p.theme.font.color.darkGray};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  word-break: break-word;
  white-space: pre-wrap;
`;

const FontPSmallCustom = styled(FontPSmall)`
  color: ${(p) => p.theme.font.color.darkGray};
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
`;

const CardCustom = styled(Card)`
  border-bottom: thin solid ${(p) => p.theme.color.secondary};
  padding: 10px 9px 9px 10px;
`;

const LnlnOfficeIconMenuTag = styled(IconMenuTag)`
  display: inline-block;
  line-height: 20px;
  background-color: ${(p) => p.theme.lnlnOffice.skyBlue3};
  color: ${(p) => p.theme.lnlnOffice.darkBlue2};
  margin: 4px 0px 2px 0px;
`;

export const SearchFromHospitalHistoriesCard = (props: Props) => {
  const { appointment } = props;
  const searchParams = new URLSearchParams(window.location.search.substr(1));
  const facilityType = searchParams.get("type");
  const menuName = appointment.menu ? appointment.menu.displayName : "";
  const isLnlnOfficeMenu = Boolean(appointment.plan);

  return (
    <CardCustom disabled={!appointment.canCreateAppointment}>
      {!appointment.canCreateAppointment && (
        <WarningMessage>
          {facilityType === FACILITY_TYPE.HOSPITAL && renderResource.j002}
          {facilityType === FACILITY_TYPE.PHARMACY && renderResource.j003}
        </WarningMessage>
      )}
      <FontPBoldCustom>{appointment.hospitalName}</FontPBoldCustom>
      <FontPSmallCustom>
        {isLnlnOfficeMenu
          ? menuName + " : " + appointment.plan?.planName
          : menuName}
      </FontPSmallCustom>
      <FontPSmallCustom>
        {formatPeriod(appointment.from, appointment.to)}
        {renderResource.j007}
      </FontPSmallCustom>
      {isLnlnOfficeMenu && (
        <LnlnOfficeIconMenuTag>{renderResource.j010}</LnlnOfficeIconMenuTag>
      )}
    </CardCustom>
  );
};
