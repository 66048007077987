import React, { useCallback, useRef, useState, useEffect } from "react";
import styled from "styled-components";
import dateFns from "date-fns";
import Panel from "../../atoms/panels/Panel";
import FontP from "../../atoms/fonts/FontP";
import Image from "../../atoms/others/Image";
import FullWidthButton from "../../atoms/buttons/ButtonFullWidth";
import Button from "../../atoms/buttons/Button";
import InputText from "../../atoms/forms/InputText";
import InputFile from "../../atoms/forms/InputFile";
import HeadingForm from "../../molecules/headings/HeadingForm";
import { japaneseList } from "../../../Resources/japaneseList";
import { checkTextInput } from "../../../Utils/PatientAttachment";
import { toastError } from "../../../Utils/Utility";
import {
  APPOINTMENT_STATUS,
  PATIENT_ATTACHMENT,
} from "../../../Utils/Constant";
import GraphQLClient from "../../../Utils/GraphQLClient";
import { GetApptStatusReservationDayPatientAttachments } from "../../../Utils/GraphQLQueries";

const renderResource =
  japaneseList.organisms.reservationForm.ReservationUploadFileReview;

const CANCEL_STATUS = [
  APPOINTMENT_STATUS.CANCELED_BEFORE_APPROVING,
  APPOINTMENT_STATUS.CANCELED_DOCTOR,
  APPOINTMENT_STATUS.CANCELED_PATIENT_FROM_TODAY,
  APPOINTMENT_STATUS.CANCELED_PATIENT_PREVIOUS_DAY,
];

const REJECT_STATUS = [APPOINTMENT_STATUS.REJECTED];

const Container = styled.div`
  padding: 0px 15px;
  line-height: 1.3;
  margin-bottom: 10px;
`;

const FormGroup = styled.div`
  padding: 0 ${(p) => p.theme.space.normal} ${(p) => p.theme.space.short};
  background-color: #f1f3f3;
`;

const Margin = styled.div`
  padding-top: 10px;
`;

const ImageBox = styled.div`
  padding: 0 20px;
  text-align: center;
`;

const CustomImage = styled(Image)`
  max-height: 200px;
  object-fit: contain;
`;

const HeadingFormUploadFile = styled(HeadingForm)`
  background-color: ${(p) => p.theme.color.primaryVariant2};
  margin-bottom: 0px;
`;

const TextCenter = styled(FontP)`
  color: ${(p) => p.theme.color.primary};
  text-decoration: underline;
  font-size: 12pt;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const LinkUploadFile = styled.span`
  cursor: pointer;
`;

const ConfirmText = styled(Panel)`
  padding-top: 30px;
  padding-bottom: 30px;
`;

const FullWidthButtonCustom = styled(FullWidthButton)`
  height: 50px;
  font-size: 16px;
  padding: 1px 5px;
`;

const ButtonContainer = styled.div`
  padding-top: 15px;
`;

const FullWidthOutlinedButton = styled(Button)`
  width: 100%;
  color: ${(p) => p.theme.color.primary};
  background-color: ${(p) => p.theme.color.fontInvert};
  border-radius: 5px;
  border: thin solid ${(p) => p.theme.color.primary};
  height: 50px;
`;

const InputTextStyle = styled(InputText)`
  width: 100%;
  border-radius: 5px;
  height: 44px;
  background-color: #ffffff;
`;

const CustomInputFile = styled(InputFile)`
  display: none;
`;

export const ReservationUploadFileReview = (props) => {
  const {
    file,
    handleSelectImage,
    handleAddNewFileToThumbnailList,
    handleBackUploadForm,
    isFromAppointmentDetailPage,
    appointmentCreateTime,
  } = props;

  const fileSelectedName = file.fileName
    ? file.fileName
    : file.originalFile.name
    ? file.originalFile.name.split(".").slice(0, -1).join("")
    : "";

  const [fileName, setFileName] = useState("");

  useEffect(() => {
    setFileName(fileSelectedName);
  }, []);

  const handleClickUpload = useCallback(async (e) => {
    // default event stop
    e.preventDefault();
    // Check validation file name
    const fileNameInput = checkTextInput(fileName.trim());
    switch (fileNameInput) {
      case PATIENT_ATTACHMENT.ERROR.FILE_NAME_EMPTY:
        toastError(renderResource.j006);
        return;
      case PATIENT_ATTACHMENT.ERROR.FILE_NAME_MAX_CHAR:
        toastError(renderResource.j007);
        return;
      case PATIENT_ATTACHMENT.ERROR.FILE_NAME_INCLUDE_UNUSUAL:
        toastError(renderResource.j008);
        return;
      default:
        break;
    }
    // Check Appointment status and reservation day
    if (appointmentCreateTime) {
      try {
        const { data, errors } = await GraphQLClient.query({
          query: GetApptStatusReservationDayPatientAttachments,
          variables: {
            createTime: appointmentCreateTime,
          },
        });
        if (errors) {
          // System error occurs
          toastError(renderResource.j010);
          return;
        }
        const videoCallDay = dateFns.parse(data.appointment.from);
        const lastTimeUploadFile = dateFns.addDays(
          videoCallDay,
          PATIENT_ATTACHMENT.MAX_DAY_CAN_UPLOAD
        );
        const checkExpiredUploadFile =
          Date.now() > lastTimeUploadFile.getTime();
        if (checkExpiredUploadFile) {
          // Error if photo upload deadline had passed
          toastError(renderResource.j009);
          return;
        }
        if (REJECT_STATUS.includes(data.appointment.status)) {
          // Error if booking had been rejected
          toastError(renderResource.j011);
          return;
        }
        if (CANCEL_STATUS.includes(data.appointment.status)) {
          // Error if booking had been canceled
          toastError(renderResource.j012);
          return;
        }
      } catch (error) {
        // System error occurs
        toastError(renderResource.j010);
        return;
      }
    }

    file.fileName = fileNameInput;
    handleAddNewFileToThumbnailList(file);
  });

  const handleInputFileName = useCallback((e) => {
    // default event stop
    e.preventDefault();
    const { value } = e.target;
    setFileName(value);
  });

  //--- Ref declaration
  const inputEl = useRef(null);

  //--- Callbacks declaration
  const handleClickChangeImage = useCallback((e) => {
    // default event stop
    e.preventDefault();
    // Open file select
    inputEl.current.click();
  });

  const handleChangeImage = useCallback((e) => {
    // default event stop
    e.preventDefault();
    // Open file select
    const imageSeleted = e.target.files.item(0);
    const fileNameSelect = imageSeleted.name
      ? imageSeleted.name.split(".").slice(0, -1).join("")
      : "";
    setFileName(fileNameSelect);
    handleSelectImage(imageSeleted);
  });

  const handleCancelButton = useCallback((e) => {
    handleBackUploadForm();
  });

  return (
    <React.Fragment>
      <ConfirmText>
        <FontP size="s" align="center" style={{ fontSize: "16px" }}>
          {renderResource.j001}
        </FontP>
      </ConfirmText>
      <ImageMemo file={file.originalFile} />
      <HeadingFormUploadFile hideRequired={true}>
        {renderResource.j002}
      </HeadingFormUploadFile>
      <FormGroup>
        <Margin>
          <InputTextStyle
            id="reserved-image-name-input"
            onChange={handleInputFileName}
            name="fileName"
            value={fileName}
          />
        </Margin>
      </FormGroup>
      <CustomInputFile ref={inputEl} onChange={handleChangeImage} />
      <TextCenter size="s" align="center">
        <LinkUploadFile id="change-image-link" onClick={handleClickChangeImage}>
          {renderResource.j003}
        </LinkUploadFile>
      </TextCenter>
      <Container>
        <FullWidthButtonCustom
          id="register-image-share-button"
          onClick={handleClickUpload}
        >
          {renderResource.j004}
        </FullWidthButtonCustom>
        {!isFromAppointmentDetailPage && (
          <ButtonContainer>
            <FullWidthOutlinedButton
              id="register-image-share-back-button"
              onClick={handleCancelButton}
            >
              {renderResource.j005}
            </FullWidthOutlinedButton>
          </ButtonContainer>
        )}
      </Container>
    </React.Fragment>
  );
};

const ImageMemo = React.memo((props) => {
  const objectURL = URL.createObjectURL(props.file);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(objectURL);
    };
  }, [objectURL]);

  return (
    <ImageBox>
      <CustomImage src={objectURL} />
    </ImageBox>
  );
});
