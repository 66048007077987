import React, { Component } from "react";
import styled from "styled-components";

import Typography1 from "../atoms/fonts/Typography1";
import Typography3 from "../atoms/fonts/Typography3";
import IconMenuTag from "../atoms/icons/IconMenuTag";
import Card from "./Card";

import { japaneseList } from "../../Resources/japaneseList";
const renderResource = japaneseList.molecules.hospital;

const CustomTypography1 = styled(Typography1)`
  width: 100%;
`;

const CustomTypography3 = styled(Typography3)`
  width: 100%;
`;

const LnlnOfficeIconMenuTag = styled(IconMenuTag)`
  display: inline-block;
  background-color: ${(p) => p.theme.lnlnOffice.skyBlue3};
  color: ${(p) => p.theme.lnlnOffice.darkBlue2};
`;

function removeDuplication(tagList) {
  return tagList.filter(
    (displayTagName, index, self) => self.indexOf(displayTagName) === index
  );
}
class Hospital extends Component {
  render() {
    const { hospital } = this.props;
    const tagList = removeDuplication(
      hospital.menus
        .filter((menu) => menu.createAppointmentByPatient)
        .map((menu) => menu.displayTagName)
    );

    const badge = (
      <div>
        {tagList.map((value) =>
          value === renderResource.LnlnOffice ? (
            <LnlnOfficeIconMenuTag>{value}</LnlnOfficeIconMenuTag>
          ) : (
            <IconMenuTag>{value}</IconMenuTag>
          )
        )}
      </div>
    );
    return (
      <div>
        <Card>
          <CustomTypography1>{hospital.name}</CustomTypography1>
          <CustomTypography3>{hospital.address}</CustomTypography3>
          {badge}
        </Card>
      </div>
    );
  }
}

export default Hospital;
