import styled from "styled-components";
import { useHistory } from "react-router-dom";

import FontBase from "../../atoms/fonts/FontBase";
import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";
import { japaneseList } from "../../../Resources/japaneseList";

const renderResource = japaneseList.molecules.lnlnOffice;

const OfficeButton = styled(ButtonFullWidth)`
  padding-right: 4px;
  padding-left: 4px;
  background-color: ${(p) => p.theme.lnlnOffice.skyBlue1};
  color: ${(p) => p.theme.lnlnOffice.darkBlue2};
  border: 1px solid ${(p) => p.theme.lnlnOffice.smokyBlue};
  font-size: 1rem;
`;
const SmallFont = styled(FontBase)`
  font-size: 0.8rem;
  font-weight: normal;
  color: ${(p) => p.theme.lnlnOffice.darkBlue2};
`;

interface Props {
  GAid: string;
}

export const LnlnOfficeButton: React.FC<Props> = (props) => {
  const history = useHistory();
  const handleClick: () => void = () => {
    history.push("/enterprise/top");
  };

  const { GAid } = props;

  return (
    <OfficeButton onClick={handleClick} id={GAid}>
      {renderResource.button.text}
      <SmallFont>{renderResource.button.small_text}</SmallFont>
    </OfficeButton>
  );
};
