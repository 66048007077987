import React, { Component } from "react";
import PrefectureAreas from "../../molecules/prefectureList/PrefectureAreas";
import Heading1 from "../../atoms/headings/Heading1";
import { japaneseList } from "../../../Resources/japaneseList";
import { FACILITY_TYPE } from "../../../Utils/Constant";

class HospitalSearchByAdress extends Component {
  render() {
    const renderResource =
      japaneseList.organisms.SearchHospitalByAddress.render;
    const { searchResultPageUrl, hospitalCounts } = this.props;
    const searchParams = new URLSearchParams(window.location.search.substr(1));
    const facilityType = searchParams.get("type");

    return (
      <React.Fragment>
        <Heading1>
          {facilityType === FACILITY_TYPE.HOSPITAL && renderResource.Title.j001}
          {facilityType === FACILITY_TYPE.PHARMACY && renderResource.Title.j002}
        </Heading1>
        <PrefectureAreas
          searchResultPageUrl={searchResultPageUrl}
          hospitalCounts={hospitalCounts}
        />
      </React.Fragment>
    );
  }
}

export default HospitalSearchByAdress;
