import React, { Component } from "react";
import styled from "styled-components";
import { Auth } from "aws-amplify";

import Heading1 from "../atoms/headings/Heading1";
import Heading3 from "../atoms/headings/Heading3";
import AreaFont from "../molecules/area/AreaFont";
import LinkAnoUnder from "../atoms/links/LinkAnoUnder";
import { japaneseList } from "../../Resources/japaneseList";
import { isWebView } from "../device";

const Container = styled.div`
  margin-bottom: 30px;
`;

const Text = styled.span`
  display: block;
`;

class ConsumerPolicy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    Auth.currentUserPoolUser()
      .then((user) => {
        this.setState({
          user: user,
        });
      })
      .catch((err) => {});
  }

  render() {
    const renderResource = japaneseList.organisms.ConsumerPolicy.render;
    const user = this.state.user;
    let name = "";
    const newLineCode = "%0D%0A";
    name =
      user !== null ? renderResource.j001 + user.username + newLineCode : "";
    const userAgent = window.navigator.userAgent;
    const msg =
      "mailto:inquiry@caradamedica.co.jp?subject=" +
      renderResource.j002 +
      "&body=" +
      name +
      userAgent +
      newLineCode +
      newLineCode +
      renderResource.j003 +
      newLineCode +
      "--------------------------" +
      newLineCode +
      renderResource.j004 +
      newLineCode +
      "-------------------------" +
      newLineCode;

    return (
      <Container>
        <div>
          <Heading1>
            {renderResource.Container.div.PrimaryHeading.j001}
          </Heading1>
          <Heading3>
            {renderResource.Container.div.SecondaryHeading.j001}
          </Heading3>
          <AreaFont>{renderResource.Container.div.Paragraph.j001}</AreaFont>

          <Heading3>
            {renderResource.Container.div.SecondaryHeading.j002}
          </Heading3>
          <AreaFont>{renderResource.Container.div.Paragraph.j002}</AreaFont>

          <Heading3>
            {renderResource.Container.div.SecondaryHeading.j003}
          </Heading3>
          <AreaFont>
            <Text>{renderResource.Container.div.Paragraph.Text.j001}</Text>
            <Text>{renderResource.Container.div.Paragraph.Text.j002}</Text>
          </AreaFont>

          <Heading3>
            {renderResource.Container.div.SecondaryHeading.j004}
          </Heading3>
          <AreaFont>
            {renderResource.Container.div.Paragraph.j003}
            <br />
            {renderResource.Container.div.Paragraph.j004}
            <br />
            {renderResource.Container.div.Paragraph.j005}
            <br />
            <LinkAnoUnder href="tel:05020180298">050-2018-0298</LinkAnoUnder>
            <br />
            <LinkAnoUnder href={msg}>inquiry@caradamedica.co.jp</LinkAnoUnder>
          </AreaFont>

          <Heading3>
            {renderResource.Container.div.SecondaryHeading.j005}
          </Heading3>
          <AreaFont>{renderResource.Container.div.Paragraph.j006}</AreaFont>

          <Heading3>
            {renderResource.Container.div.SecondaryHeading.j006}
          </Heading3>
          <AreaFont>{renderResource.Container.div.Paragraph.j007}</AreaFont>

          <Heading3>
            {renderResource.Container.div.SecondaryHeading.j007}
          </Heading3>
          <AreaFont>{renderResource.Container.div.Paragraph.j008}</AreaFont>

          <Heading3>
            {renderResource.Container.div.SecondaryHeading.j008}
          </Heading3>
          <AreaFont>{renderResource.Container.div.Paragraph.j009}</AreaFont>

          <Heading3>
            {renderResource.Container.div.SecondaryHeading.j009}
          </Heading3>
          <AreaFont>
            <LinkAnoUnder
              href={
                isWebView()
                  ? `${process.env.REACT_APP_OKBIZFAQ_URL}&lnln_ob=1`
                  : `${process.env.REACT_APP_OKBIZFAQ_URL}`
              }
              id="consumer-policy-inquiry-link"
            >
              {
                renderResource.Container.div.Paragraph.PrimaryLinkToNoUnderline
                  .j001
              }
            </LinkAnoUnder>
            {renderResource.Container.div.Paragraph.j010}
          </AreaFont>
        </div>
      </Container>
    );
  }
}

export default ConsumerPolicy;
