import React from "react";
import styled from "styled-components";
import Heading1LnlnOffice from "../atoms/headings/Heading1LnlnOffice";
import Heading1LnlnOfficeByPlan from "../atoms/headings/Heading1LnlnOficceByPlan";
import FontPSmall from "../atoms/fonts/FontPSmall";
import { LnlnOfficeButton } from "../molecules/lnlnOffice/LnlnOfficeButton";
import ListHospital from "./ListHospital";
import { japaneseList } from "../../Resources/japaneseList";

const renderResource = japaneseList.organisms.searchPlanHospital.render;

const GuidanceBody = styled.div`
  padding: 20px 14px;
`;

const LnlnOfficeButtonBody = styled.div`
  padding: 30px 15px;
`;

interface HospitalListIncludePlan {
  hospitals: any[];
  planId: string;
  planName: string;
}

interface SearchPlanHospitalsProps {
  hospitalsByPlans: HospitalListIncludePlan[];
  isSystemError: boolean;
  isLnlnOficeUnregistered: boolean;
}

export const SearchPlanHospitals: React.FC<SearchPlanHospitalsProps> = ({
  hospitalsByPlans,
  isSystemError,
  isLnlnOficeUnregistered,
}) => {
  return (
    <React.Fragment>
      <Heading1LnlnOffice>{renderResource.Title}</Heading1LnlnOffice>
      {isSystemError ? (
        <>
          <GuidanceBody>
            <FontPSmall>{renderResource.GuidanceText.j001}</FontPSmall>
          </GuidanceBody>
          {/* トーストが表示される*/}
        </>
      ) : isLnlnOficeUnregistered ? (
        <>
          <GuidanceBody>
            <FontPSmall>{renderResource.GuidanceText.j002}</FontPSmall>
          </GuidanceBody>
          <LnlnOfficeButtonBody>
            <LnlnOfficeButton GAid="search-plan-to-lnln-office-registration-link" />
          </LnlnOfficeButtonBody>
        </>
      ) : (
        <>
          <GuidanceBody>
            <FontPSmall>{renderResource.GuidanceText.j001}</FontPSmall>
          </GuidanceBody>
          {hospitalsByPlans.map((hospitalsByPlan) => {
            return (
              <React.Fragment key={hospitalsByPlan.planId}>
                <Heading1LnlnOfficeByPlan>
                  {hospitalsByPlan.planName}
                </Heading1LnlnOfficeByPlan>
                {hospitalsByPlan.hospitals.length === 0 ? (
                  <GuidanceBody>
                    <FontPSmall>{renderResource.GuidanceNoHospital}</FontPSmall>
                  </GuidanceBody>
                ) : (
                  <ListHospital
                    hospitals={hospitalsByPlan.hospitals}
                    gaId={renderResource.GAid}
                  />
                )}
              </React.Fragment>
            );
          })}
        </>
      )}
    </React.Fragment>
  );
};
