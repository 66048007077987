import styled from "styled-components";
import { useHistory } from "react-router-dom";

import Image from "../../atoms/others/Image";
import ImgPath from "../../../images/lnlnOffice/logo.svg";
import FontBase from "../../atoms/fonts/FontBase";
import Button from "../../atoms/buttons/Button";
import { japaneseList } from "../../../Resources/japaneseList";

const renderResource = japaneseList.molecules.lnlnOffice;

const Container = styled.div`
  padding: 16px 14px;
  background: ${(p) => p.theme.lnlnOffice.skyBlue1};
  border-radius: 6px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;
const ImageWrapper = styled.div`
  width: 80px;
`;
const Font = styled(FontBase)`
  color: ${(p) => p.theme.lnlnOffice.darkBlue2};
  font-weight: bold;
`;
const Button1 = styled(Button)`
  width: 100%;
  max-width: 270px;
  padding: 8px;
  background-color: ${(p) => p.theme.lnlnOffice.darkBlue1};
  color: ${(p) => p.theme.lnlnOffice.white};
  font-size: 0.9rem;
  border: unset;
`;

interface Props {
  GAid: string;
}

export const LnlnOfficeBanner: React.FC<Props> = (props) => {
  const history = useHistory();
  const handleClick: () => void = () => {
    history.push("/enterprise/top");
  };

  const { GAid } = props;

  return (
    <Container>
      <ImageWrapper>
        <Image src={ImgPath} />
      </ImageWrapper>
      <Font>{renderResource.banner.text}</Font>
      <Button1 onClick={handleClick} id={GAid}>
        {renderResource.banner.button_text}
      </Button1>
    </Container>
  );
};
