import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";
import FontBase from "../../atoms/fonts/FontBase";
import FontPSmall from "../../atoms/fonts/FontPSmall";
import Heading1LnlnOfficeByPlan from "../../atoms/headings/Heading1LnlnOficceByPlan";
import ListHospital from "../ListHospital";

const renderResource = japaneseList.pages.EnterpriseCompletePage.render;

const FontBaseLnlnOffice = styled(FontBase)`
  text-align: center;
  color: ${(p) => p.theme.lnlnOffice.darkBlue2};
  font-weight: 600;
  margin-bottom: 10px;
`;

const GuidanceBody = styled.div`
  padding: 20px 14px;
`;

const ButtonContainer = styled.div`
  padding: 0px 30px;
  margin: 15px 0px;
`;

export const EnterpriseCompleteContent = ((props) => {
  const { plans, hospitalsByPlans, isSystemError, isLoading } = props;
  const history = useHistory();

  const navigateToHospitalSearchByPlan = (planId) => {
    history.push(`/search/plan`, {
      planId,
    });
  };
  return (
    <React.Fragment>
      <GuidanceBody>
        <FontBaseLnlnOffice>{renderResource.Content.j001}</FontBaseLnlnOffice>
        <FontPSmall>{renderResource.Content.j002}</FontPSmall>
      </GuidanceBody>
      {isLoading ? (
        <></>
      ) : (
        <>
          {plans.map((plan) => {
            return (
              <div key={plan.planId}>
                <Heading1LnlnOfficeByPlan>
                  {plan.planName}
                </Heading1LnlnOfficeByPlan>
                {isSystemError || !isHospitals(hospitalsByPlans, plan) ? (
                  <ButtonContainer>
                    <ButtonFullWidth
                      onClick={() =>
                        navigateToHospitalSearchByPlan(plan.planId)
                      }
                    >
                      {plan.planName + renderResource.Button.j001}
                    </ButtonFullWidth>
                  </ButtonContainer>
                ) : (
                  <div>
                    <ListHospital
                      hospitals={matchingHospitals(hospitalsByPlans, plan)}
                      gaId={renderResource.GAid}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </>
      )}
    </React.Fragment>
  );
});

const isHospitals = (hospitalsByPlans, plan) => {
  return matchingHospitals(hospitalsByPlans, plan)?.length > 0;
};

const matchingHospitals = (hospitalsByPlans, plan) => {
  const hospitalsByPlan = hospitalsByPlans.find((hospitalsByPlan) => {
    return hospitalsByPlan.planId === plan.planId;
  });
  return hospitalsByPlan?.hospitals;
};
