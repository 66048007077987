import React, { Component } from "react";
import styled from "styled-components";

import FontP from "../atoms/fonts/FontP";

import { APPOINTMENT_STATUS } from "../../Utils/Constant";
import { formatPeriod } from "../../Utils/Utility";
import { japaneseList } from "../../Resources/japaneseList";

const Panel = styled.div`
  margin-bottom: 15px;
`;

const getAppointmentStatusText = (status, medicalMethod) => {
  switch (status) {
    case APPOINTMENT_STATUS.BEFORE_EXAM:
    case APPOINTMENT_STATUS.EXAM_COMPLETED:
    case APPOINTMENT_STATUS.PAYMENT_COMPLETED:
      return japaneseList.appointmentStatus[status][medicalMethod];
    case APPOINTMENT_STATUS.UNAPPROVED:
    case APPOINTMENT_STATUS.CANCELED_BEFORE_APPROVING:
    case APPOINTMENT_STATUS.REJECTED:
      return japaneseList.appointmentStatus[status];
    default:
      return japaneseList.appointmentStatus.canceled;
  }
};

class TimeStatusAppointmentDetail extends Component {
  render() {
    const renderResource =
      japaneseList.molecules.TimeStatusAppointmentDetail.render;
    const { appointment } = this.props;
    return (
      <Panel>
        <FontP color="accent" weight="bold">
          {renderResource.div.Container.Title.j001}
        </FontP>
        <FontP>
          {formatPeriod(appointment.from, appointment.to) +
            renderResource.div.Container.Detail.j001}
        </FontP>
        <FontP>
          {`（${getAppointmentStatusText(
            appointment.status,
            appointment.menu.medicalMethod
          )}）`}
        </FontP>
      </Panel>
    );
  }
}
export default TimeStatusAppointmentDetail;
