import styled from "styled-components";

import Image from "../../atoms/others/Image";
import ImgPath from "../../../images/lnlnOffice/logo.svg";
import FontBase from "../../atoms/fonts/FontBase";
import FontPBold from "../../atoms/fonts/FontPBold";
import { japaneseList } from "../../../Resources/japaneseList";

const Container = styled.div`
  padding: 16px 14px;
  background: ${(p) => p.theme.lnlnOffice.skyBlue1};
  border-radius: 6px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;
const ImageWrapper = styled.div`
  width: 100px;
`;
const Accent = styled(FontBase)`
  width: 160px;
  text-align: center;
  background-color: ${(p) => p.theme.lnlnOffice.darkBlue1};
  color: ${(p) => p.theme.lnlnOffice.white};
  border-radius: 18px;
`;
const Header = styled(FontPBold)`
  color: ${(p) => p.theme.lnlnOffice.darkBlue1};
  font-size: 1.2rem;
`;

export const EnterpriseHeader: React.FC = () => {
  const renderResource = japaneseList.pages.EnterpriseAuthenticationPage.render;

  return (
    <Container>
      <ImageWrapper>
        <Image src={ImgPath} />
      </ImageWrapper>
      <Accent>{renderResource.accent}</Accent>
      <Header>{renderResource.Title}</Header>
    </Container>
  );
};
