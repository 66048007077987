import React from "react";
import styled from "styled-components";
import FontPSmallCenter from "../atoms/fonts/FontPSmallCenter";
import FontPSSSCenter from "../atoms/fonts/FontPSSSCenter";
import ButtonFullWidth from "../atoms/buttons/ButtonFullWidth";
import LinkTo from "../atoms/links/LinkTo";
import { japaneseList } from "../../Resources/japaneseList";

const renderResource = japaneseList.molecules.planCardBody;

const StyledCardBody = styled.div`
  background: ${(p) => p.theme.lnlnOffice.skyBlue2};
  padding: 15px 22.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const FontPSmallCenterBlack = styled(FontPSmallCenter)`
  color: ${(p) => p.theme.font.color.black};
`;

const FontPSSSCenterBlack = styled(FontPSSSCenter)`
  color: ${(p) => p.theme.font.color.black};
`;

interface CardBodyProps {
  hasPlanAppointment: boolean;
  hasPlanAppointmentHistory: boolean;
  planName: string;
  planId: string;
  navigateToHospitalSearchFromHistory: () => void;
  navigateToHospitalSearchByPlan: () => void;
}

const CardBody: React.FC<CardBodyProps> = ({
  hasPlanAppointment,
  hasPlanAppointmentHistory,
  planName,
  planId,
  navigateToHospitalSearchFromHistory,
  navigateToHospitalSearchByPlan,
}) => {
  return (
    <StyledCardBody>
      {hasPlanAppointment ? (
        <FontPSmallCenterBlack>
          {renderResource.AppointmentExistMessage}
          <br />
          {renderResource.CheckAppointmentMessage}
        </FontPSmallCenterBlack>
      ) : hasPlanAppointmentHistory ? (
        <>
          <ButtonFullWidth
            onClick={navigateToHospitalSearchFromHistory}
            id={`${planId}-lnln-office-appointment-history-search-link`}
          >
            {renderResource.AppointmentHistoryButtonText}
          </ButtonFullWidth>

          <FontPSSSCenterBlack>
            {renderResource.ChangeHospitalMessage}
            <LinkTo
              to="#"
              onClick={(e) => {
                e.preventDefault();
                navigateToHospitalSearchByPlan();
              }}
              id={`${planId}-lnln-office-change-hospital-list-link`}
            >
              {renderResource.ChangeHospitalLinkText}
            </LinkTo>
          </FontPSSSCenterBlack>
        </>
      ) : (
        <>
          <FontPSmallCenterBlack>
            {renderResource.FirstVisitRequestMessage}
          </FontPSmallCenterBlack>
          <ButtonFullWidth
            onClick={navigateToHospitalSearchByPlan}
            id={`${planId}-lnln-office-hospital-list-link`}
          >
            {planName + renderResource.ReservationSuffix}
          </ButtonFullWidth>
        </>
      )}
    </StyledCardBody>
  );
};

export default CardBody;
