import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import AuthenticatedTemplate from "../../templates/AuthenticatedTemplate";
import Heading1LnlnOffice from "../../atoms/headings/Heading1LnlnOffice";
import { EnterpriseCompleteContent } from "../../organisms/enterprise/EnterpriseCompleteContent";
import { japaneseList } from "../../../Resources/japaneseList";
import Titles from "../../../Resources/Titles";
import { EnterpriseStepperForm } from "../../organisms/enterprise/EnterpriseStepperForm";
import {
  ENTERPRISE_REGISTER_STEP,
  ENTERPRISE_SIGNIN_STEPS,
  ENTERPRISE_SIGNUP_STEPS,
} from "../../../Utils/Constant";
import GraphQLClient from "../../../Utils/GraphQLClient";
import { ListHospitalsByContractPlans } from "../../../Utils/GraphQLQueries";
import { isLnln } from "../../../Utils/checkLnln";

export const EnterpriseCompletePage = (props) => {
  const renderResource = japaneseList.pages.EnterpriseCompletePage.render;
  const isFlowSignUp =
    props.location.state && props.location.state.flow === "signup";
  const currentStep = isFlowSignUp
    ? ENTERPRISE_SIGNUP_STEPS[ENTERPRISE_REGISTER_STEP.COMPLETED]
    : ENTERPRISE_SIGNIN_STEPS[ENTERPRISE_REGISTER_STEP.COMPLETED];

  const registerSteps = isFlowSignUp
    ? ENTERPRISE_SIGNUP_STEPS
    : ENTERPRISE_SIGNIN_STEPS;

  if (!props.location.state || !props.location.state.isFinish) {
    return <Redirect to="/enterprise/authorize" />;
  }

  const [isSystemError, setSystemError] = useState(false);
  const [plans, setPlans] = useState([]);
  const [hospitalsByPlans, setHospitalsByPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getHospitalListByPlan = async (authorizeEnterprise) => {
    try {
      setIsLoading(true);
      const contractPlanIds = authorizeEnterprise.contractPlans.map(
        (contractPlan) => contractPlan.contractPlanId
      );
      const { data, errors } = await GraphQLClient.query({
        query: ListHospitalsByContractPlans,
        variables: {
          contractPlanIds,
          from: isLnln() ? "lnln" : "carada",
        },
      });
      if (errors) {
        setSystemError(true);
      } else {
        setHospitalsByPlans(data.hospitalsByContractPlans);
      }
      setIsLoading(false);
    } catch (error) {
      setSystemError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (props.location.state && props.location.state.authorizeEnterprise) {
      const authorizeEnterprise = props.location.state.authorizeEnterprise;
      const uniquePlans = extractUniquePlans(authorizeEnterprise);
      if (uniquePlans?.length > 0) {
        const sortedUniquePlans = sortAscendingOrderPlanId(uniquePlans);
        setPlans(sortedUniquePlans);
      }
      getHospitalListByPlan(authorizeEnterprise);
    }
  }, [props.location.state]);

  const main = (
    <React.Fragment>
      <Heading1LnlnOffice>{renderResource.Title}</Heading1LnlnOffice>
      <EnterpriseStepperForm
        registerSteps={registerSteps}
        currentStep={currentStep}
      />
      <EnterpriseCompleteContent
        plans={plans}
        hospitalsByPlans={hospitalsByPlans}
        isSystemError={isSystemError}
        isLoading={isLoading}
      />
    </React.Fragment>
  );

  return (
    <AuthenticatedTemplate
      title={Titles.enterpriseCompleted}
      main={main}
      isNoIndex={true}
    />
  );
};

const extractUniquePlans = (enterprise) => {
  const uniquePlansMap = new Map();

  enterprise.contractPlans.forEach((contractPlan) => {
    const { planId } = contractPlan.plan;
    uniquePlansMap.set(planId, contractPlan.plan);
  });

  return Array.from(uniquePlansMap.values());
};

/**
 * プランIDでプランリストを昇順でソートする関数
 * @param {Plan[]} plans プランオブジェクトの配列
 * @return {Plan[]} ソート済みのプランオブジェクトの配列
 */
const sortAscendingOrderPlanId = (plans) => {
  return [...plans].sort((a, b) => {
    return a.planId.localeCompare(b.planId, undefined, {
      numeric: true,
    });
  });
};
