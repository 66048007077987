import React, { useCallback, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import Button from "../../atoms/buttons/Button";
import ButtonOutlined from "../../atoms/buttons/ButtonOutlined";
import InputFile from "../../atoms/forms/InputFile";
import { japaneseList } from "../../../Resources/japaneseList";
import { deviceOS, DeviceOSName, isWebView } from "../../device";

const uploadFilePopupResource =
  japaneseList.organisms.reservationForm.UploadFilePopup;

const styles = () => ({
  dialogTitle: {
    "text-align": "center",
    "white-space": "pre-wrap",
    padding: "30px 21px 25px 21px",
  },
  dialogAction: {
    display: "grid",
    "grid-template-columns": "1fr",
    "grid-gap": "14px",
    padding: "5px 21px 30px 21px",
    margin: 0,
  },
  dialogContent: {
    maxWidth: "303px",
  },
  dialogContentLnln: {
    maxWidth: "303px",
    paddingBottom: "15px",
  },
  dialogButton: {
    margin: 0,
    height: "50px",
    "font-size": "16px",
    "font-weight": "bold",
  },
  img: {
    width: "21px",
    height: "14px",
    marginTop: "2px",
  },
});

const DialogTitleText = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${(p) => p.theme.color.primary};
  line-height: 23px;
`;

const DialogContentText = styled.span`
  font-size: 14px;
  color: #393939;
  line-height: 22px;
`;

const ContentCautionItem = styled.div`
  margin-top: 10px;
  font-size: 12px;
  color: #999999;
  line-height: 20px;
`;

const ContentItem = styled.div``;

const CustomInputFile = styled(InputFile)`
  display: none;
`;

const UploadFilePopupNoStyle = (props) => {
  const {
    classes,
    isOpen,
    handleSelectImage,
    handleClosePopup,
    isFromDetailAppointment,
  } = props;
  const osDeviceName = deviceOS();
  //--- Ref declaration
  const inputEl = useRef(null);

  //--- Callbacks declaration
  const handleClickUpload = useCallback((e) => {
    // default event stop
    e.preventDefault();
    // Open file select
    inputEl.current.click();
  });

  const handleClose = useCallback((e) => {
    // default event stop
    e.preventDefault();
    handleClosePopup();
  });

  const handleClickSelectFile = useCallback((e) => {
    // default event stop
    e.preventDefault();
    // Open file select
    handleSelectImage(e.target.files.item(0));
    handleClosePopup();
  });

  const renderLnlnNote = (deviceOSName) => {
    if (deviceOSName === DeviceOSName.Android) {
      if (isFromDetailAppointment) {
        return (
          <ContentCautionItem>
            {uploadFilePopupResource.lnlnCautionAndroidDetailApp1}
            <br />
            {uploadFilePopupResource.lnlnCautionAndroidDetailApp2}
          </ContentCautionItem>
        );
      } else {
        return (
          <ContentCautionItem>
            {uploadFilePopupResource.lnlnCautionAndroidRegisterApp1}
            <br />
            {uploadFilePopupResource.lnlnCautionAndroidRegisterApp2}
          </ContentCautionItem>
        );
      }
    } else {
      return (
        <ContentCautionItem>
          {uploadFilePopupResource.lnlnCaution1}
          <br />
          {uploadFilePopupResource.lnlnCaution2}
        </ContentCautionItem>
      );
    }
  };

  //--- Rendering
  return (
    <React.Fragment>
      <Dialog open={isOpen}>
        <DialogTitle className={classes.dialogTitle}>
          <DialogTitleText>{uploadFilePopupResource.title}</DialogTitleText>
        </DialogTitle>
        <DialogContent
          className={
            isWebView() ? classes.dialogContentLnln : classes.dialogContent
          }
        >
          <ContentItem>
            <DialogContentText>
              {uploadFilePopupResource.description}
            </DialogContentText>
          </ContentItem>
          {isWebView() && renderLnlnNote(osDeviceName)}
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <CustomInputFile ref={inputEl} onChange={handleClickSelectFile} />
          <Button
            id="select-dialog-image-share-button"
            className={classes.dialogButton}
            onClick={handleClickUpload}
          >
            {uploadFilePopupResource.uploadButton}
          </Button>
          <ButtonOutlined
            id="select-dialog-image-share-back-button"
            className={classes.dialogButton}
            onClick={handleClose}
          >
            {uploadFilePopupResource.cancelButton}
          </ButtonOutlined>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export const UploadFilePopup = withStyles(styles)(UploadFilePopupNoStyle);
