import React, { useState } from "react";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";
import { EnterpriseContractPlanList } from "./EnterpriseContractPlanList";
import { ENTERPRISEAUTHORIZATION_MAINEFFECT } from "../../../Utils/Constant";
import JapaneseTermsOfServiceForEnterprise from "../../../Resources/JapaneseTermsOfServiceForEnterprise";
import { Hub } from "aws-amplify";
import GraphQLClient from "../../../Utils/GraphQLClient";
import * as Sentry from "@sentry/browser";
import { RegisterEnterpriseAuthorization } from "../../../Utils/GraphQLQueries";
import { withRouter } from "react-router-dom";

const ButtonContainer = styled.div`
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 15px;
`;

const ConfirmButton = styled(ButtonFullWidth)`
  margin-top: 5px;
  height: 50px;
`;

const BackButton = styled(ButtonFullWidth)`
  color: ${(p) => p.theme.color.primary};
  background-color: ${(p) => p.theme.color.fontInvert};
  border-radius: 5px;
  border: 1.5px solid ${(p) => p.theme.color.primary};
  font-size: 16px;
  padding: 1px 5px;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
  cursor: pointer;
  height: 50px;
  margin-bottom: 20px;
`;
export const EnterpriseConfirmForm = withRouter((props) => {
  const renderResource =
    japaneseList.organisms.enterprise.EnterpriseConfirmForm.render;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConfirmButtonClick = async () => {
    if (isSubmitting) {
      return false;
    }
    try {
      setIsSubmitting(true);
      const { data, errors } = await GraphQLClient.mutate({
        mutation: RegisterEnterpriseAuthorization,
        variables: {
          mainEffect: ENTERPRISEAUTHORIZATION_MAINEFFECT.EXECUTE,
          enterpriseAuthorizationCodes:
            props.enterpriseAuthorizationCodes.filter(Boolean),
          termsOfServiceForEnterpriseAgree: props.isAgreed,
          termsOfServiceForEnterpriseRevisionDate:
            JapaneseTermsOfServiceForEnterprise.date.revision,
          employeeNumber: props.employeeNumber,
          confirmEmployeeNumber: props.employeeNumber,
        },
      });

      if (errors) {
        setIsSubmitting(false);
        if (errors.find((e) => e.errorType === "E01")) {
          toastError(renderResource.Submit.j001);
        } else if (errors.find((e) => e.errorType === "E02")) {
          toastError(renderResource.Submit.j002);
        } else if (errors.find((e) => e.errorType === "E03")) {
          toastError(renderResource.Submit.j003);
        } else if (errors.find((e) => e.errorType === "E04")) {
          toastError(renderResource.Submit.j004);
        } else if (errors.find((e) => e.errorType === "E05")) {
          toastError(renderResource.Submit.j005);
        } else if (errors.find((e) => e.errorType === "E06")) {
          toastError(renderResource.Submit.j006);
        } else if (errors.find((e) => e.errorType === "E07")) {
          toastError(renderResource.Submit.j007);
        } else if (errors.find((e) => e.errorType === "E08")) {
          toastError(renderResource.Submit.j008);
        } else {
          toastError(renderResource.Submit.j009);
        }
        return;
      }
      props.history.push({
        pathname: "/enterprise/complete",
        state: {
          isFinish: true,
          flow: props.location.state && props.location.state.flow,
          from: props.location.state && props.location.state.from,
          authorizeEnterprise: data.authorizeEnterprise,
        },
      });
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      setIsSubmitting(false);
    }
  };

  const handleBackButtonClick = () => {
    props.history.push({
      pathname: "/enterprise/authorize",
      state: {
        enterpriseAuthorizationCodes: props.enterpriseAuthorizationCodes,
        flow: props.location.state && props.location.state.flow,
        from: props.location.state && props.location.state.from,
      },
    });
  };

  const toastError = (errorMessage) => {
    Hub.dispatch("msg", {
      event: "open",
      data: {
        message: errorMessage,
        level: "error",
      },
    });
  };

  return (
    <React.Fragment>
      {props.enterprise && (
        <EnterpriseContractPlanList
          enterpriseName={props.enterprise.enterpriseName}
          contractPlans={props.enterprise.contractPlans}
          employeeNumber={props.employeeNumber}
        />
      )}
      <ButtonContainer>
        <ConfirmButton
          id="register-enterprise-plan"
          disabled={isSubmitting}
          onClick={handleConfirmButtonClick}
        >
          {renderResource.Button.j001}
        </ConfirmButton>
      </ButtonContainer>
      <ButtonContainer>
        <BackButton
          id="return-to-enterprise-registration-page"
          onClick={handleBackButtonClick}
        >
          {renderResource.Button.j002}
        </BackButton>
      </ButtonContainer>
    </React.Fragment>
  );
});
