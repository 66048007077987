import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Hub } from "aws-amplify";
import AuthenticatedTemplate from "../templates/AuthenticatedTemplate";
import { SearchPlanHospitals } from "../organisms/SearchPlanHospitals";
import GraphQLClient from "../../Utils/GraphQLClient";
import { Account, ListHospitalsByPlan } from "../../Utils/GraphQLQueries";
import { isLnln } from "../../Utils/checkLnln";
import Titles from "../../Resources/Titles";
import { japaneseList } from "../../Resources/japaneseList";

const renderResource = japaneseList.pages.searchPlanHospitalsPage.render;

interface LocationState {
  planId?: string;
}

interface HospitalListIncludePlan {
  hospitals: any[];
  planId: string;
  planName: string;
}

interface ContractPlan {
  plan: Plan;
}

interface Plan {
  planId: string;
}

export const SearchPlanHospitalsPage = () => {
  const location = useLocation<LocationState>();
  const [isLoading, setLoading] = useState(true);
  const [hospitalsByPlans, setHospitalsByPlans] = useState<
    HospitalListIncludePlan[]
  >([]);
  const [isSystemError, setSystemError] = useState(false);
  const [isLnlnOfficeUnregistered, setLnlnOfficeUnregistered] = useState(false);

  const snackBar = (message: string) => {
    Hub.dispatch("msg", {
      event: "openAutoToast",
      data: { message: message, level: "error" },
    });
  };

  const getListHospitalsByPlan = async (planId?: string | null) => {
    const { data, errors } = await GraphQLClient.query({
      query: ListHospitalsByPlan,
      variables: {
        planId,
        from: isLnln() ? "lnln" : "carada",
      },
    });
    if (errors) {
      setSystemError(true);
      setLoading(false);
      snackBar(renderResource.Error);
      return;
    }
    const sortedHospitalsByPlans = sortAscendingOrderPlanId(
      data.hospitalsByPlan
    );
    setHospitalsByPlans(sortedHospitalsByPlans);
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data: accountData, errors } = await GraphQLClient.query({
        query: Account,
      });
      if (errors) {
        setSystemError(true);
        setLoading(false);
        snackBar(renderResource.Error);
        return;
      }
      const enterprise = accountData.account.enterprise;
      const isContractPlans =
        accountData.account.enterprise?.contractPlans?.length > 0;
      if (!enterprise || !isContractPlans) {
        setLnlnOfficeUnregistered(true);
        setLoading(false);
        return;
      }

      if (location.state?.planId) {
        const planId = location.state.planId;
        const contractPlans: ContractPlan[] =
          accountData.account.enterprise?.contractPlans;
        const isValidPlanId = contractPlans.some((contractPlan) => {
          return contractPlan.plan.planId === planId;
        });
        if (!isValidPlanId) {
          setLnlnOfficeUnregistered(true);
          setLoading(false);
          return;
        }
        await getListHospitalsByPlan(planId);
      } else {
        await getListHospitalsByPlan();
      }
      setLoading(false);
      return;
    };
    fetchData();
  }, [location.state?.planId]);

  return (
    <AuthenticatedTemplate
      title={Titles.searchEnterprise}
      main={
        <SearchPlanHospitals
          hospitalsByPlans={hospitalsByPlans}
          isSystemError={isSystemError}
          isLnlnOficeUnregistered={isLnlnOfficeUnregistered}
        />
      }
      isLoading={isLoading}
    />
  );
};

const sortAscendingOrderPlanId = (
  hospitalListIncludePlan: HospitalListIncludePlan[]
): HospitalListIncludePlan[] => {
  return [...hospitalListIncludePlan].sort((a, b) => {
    return a.planId.localeCompare(b.planId, undefined, {
      numeric: true,
    });
  });
};
