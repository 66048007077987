import React from "react";
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import GraphQLClient from "./GraphQLClient";
import { Account } from "./GraphQLQueries";
import { FEATURES } from "./Constant";

const _ = require("lodash");

export default function Guard(Component) {
  let guarded = class extends React.Component {
    constructor(props) {
      super(props);
      Auth.currentUserPoolUser()
        .then(async (user) => {
          this.setState({ loaded: true, user: user });
          const { data } = await GraphQLClient.query({ query: Account });
          if (
            Boolean(data) &&
            !this.checkPermission(data.account, window.location.pathname)
          ) {
            window.location = "/my-page";
          }
        })
        .catch((err) => {
          const redirect = window.location.pathname + window.location.search;
          const params = new URLSearchParams();
          params.append("redirect", redirect);
          const query = params.toString();
          this.props.history.push(`/login?${query}`);
        });

      this.state = {
        loaded: null,
        user: null,
      };
    }

    checkPermission(user, url) {
      let feature = _.find(FEATURES, (f) => {
        return f.pagePath === url || f.subPath.includes(url);
      });
      if (Boolean(user.organization) && Boolean(feature)) {
        return user.organization.featureSettings.some((item) => {
          return item.enable && feature.type === item.type;
        });
      }
      return true;
    }

    render() {
      return (
        this.state.loaded && (
          <Component {...this.props} user={this.state.user} />
        )
      );
    }
  };
  return withRouter(guarded);
}
