import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Auth, Hub } from "aws-amplify";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Badge from "@material-ui/core/Badge";
import MenuIcon from "@material-ui/icons/Menu";
import Notifications from "@material-ui/icons/NotificationsNone";
import IconDecorative from "../atoms/icons/IconDecorative";
import LinkStyle from "../atoms/links/LinkStyle";
import ButtonOutlined from "../atoms/buttons/ButtonOutlined";
import LeftMenu from "../molecules/menu/LeftMenu";
import DuringChattingMessage from "./DuringChattingMessage";
import { device, isWebView } from "../device";
import AppointmentReminder from "./AppointmentReminder";
import SiteContext from "../../SiteContext";
import LnlnMenuIcon from "../molecules/menu/LnlnMenuIcon";
import OnlineStandbyMessage from "../organisms/OnlineStandbyMessage";
import {
  GetTwilioToken,
  PersonalNotificationsTotalCount,
} from "../../Utils/GraphQLQueries";
import GraphQLClient from "../../Utils/GraphQLClient";
import { japaneseList } from "../../Resources/japaneseList";

import { isLnln } from "../../Utils/checkLnln";
import ImgLogoPath from "../../images/carada/logo.svg";
import ImgLogoPathLnln from "../../images/lnln/logo.svg";
import { getRedirectStateAfterAuth } from "../../Utils/GetRedirectStateAfterAuth";
const imgLogoPath = isLnln() ? ImgLogoPathLnln : ImgLogoPath;
const NOTIFICATION_NUMBER_LIMIT = 99;
const Root = styled.div`
  top: 0px;
  position: sticky;
  background-color: ${(p) => p.theme.header.bg};
  border-bottom: thin solid ${(p) => p.theme.header.border};
  z-index: ${(p) => (p.lnln ? "30" : "99")};
  margin-bottom: 20px;
`;

const Container = styled.header`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  @media screen and (orientation: landscape) and ${device.mobile} {
    height: 30px;
  }
`;

const Logo = styled.div`
  margin: 0;
  flex: 1;
`;

const HeaderIconWapper = styled(IconDecorative)`
  width: 60px;
  height: 48px;
  padding: 12px 0;
`;
const HeaderIconWapperLeft = styled(HeaderIconWapper)`
  text-align: left;
`;
const HeaderIconWapperRight = styled(HeaderIconWapper)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
`;

const LoginButton = styled(ButtonOutlined)`
  height: 20px;
  line-height: 20px;
  min-height: auto;
  padding: 0 5px;
  border-radius: 4px;
`;

const styles = (theme) => ({
  badge: {
    color: "#fff",
    backgroundColor: "#ff4d4d",
    width: "13px",
    height: "13px",
    fontSize: "0.45rem",
    marginTop: "10px",
    marginRight: "10px",
  },
});

const Img = styled.img`
  display: block;
  width: 170px;
  max-height: 60px;
  margin: 0 auto;
  @media screen and (orientation: landscape) and ${device.mobile} {
    width: 134px;
    height: auto;
  }
`;

class Header extends Component {
  onlineChatUrl = "/telemedicine/chat";
  onlineChatStandByUrl = "/telemedicine/standby";
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      showDuringChattingMessage: false,
      open: false,
    };

    this._isMounted = false;
    Hub.listen("auth", this.hubAuthListener);
  }

  hubAuthListener = (data) => {
    switch (data.payload.event) {
      case "signIn":
        if (this._isMounted) {
          this.setState({
            login: true,
          });
        }
        break;
      case "signOut":
        if (this._isMounted) {
          this.setState({
            login: false,
          });
        }
        break;
      case "updateNotificationNumber":
        this.updateBadgeCount();
        break;
      case "showDuringChattingMessage":
        if (this._isMounted) {
          this.setState({ showDuringChattingMessage: false });
        }
        break;
      default:
        break;
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    try {
      const user = await Auth.currentUserPoolUser({ bypassCache: true });
      if (this._isMounted && user) {
        this.setState({
          login: true,
        });
        this.updateBadgeCount();
      }
    } catch (err) {
      console.log(err);
      if (err.code === "UserNotFoundException") {
        this.props.history.push("/sign-out");
      }
    }

    this.isOnlineChatOrOnLineChatStandByPage()
      ? this.setState({ showDuringChattingMessage: false })
      : await this.showDuringChattingMessage();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateBadgeCount = async () => {
    try {
      const { data, errors } = await GraphQLClient.query({
        query: PersonalNotificationsTotalCount,
        variables: { isUnreadOnly: true },
      });

      if (errors) {
        console.error(
          "ListPersonalNotifications errors:",
          JSON.stringify(errors)
        );
      }
      if (this._isMounted && data && data.personalNotifications) {
        this.setState({
          notificationNumber: data.personalNotifications.totalCount,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  showDuringChattingMessage = async () => {
    const {
      data: { twilio },
    } = await GraphQLClient.query({
      query: GetTwilioToken,
    });

    if (twilio) {
      if (this._isMounted) {
        this.setState({
          showDuringChattingMessage: true,
        });
      }
    }
  };

  componentDidUpdate(prevProps) {
    const locationChanged = this.props.location !== prevProps.location;
    if (locationChanged) {
      this.setState({ open: false });
    }
  }

  handleMenuIconClick = () => {
    if (this._isMounted) {
      this.setState({ open: !this.state.open });
    }
  };

  isOnlineChatOrOnLineChatStandByPage = () => {
    return (
      window.location.pathname.startsWith(this.onlineChatUrl) ||
      window.location.pathname.startsWith(this.onlineChatStandByUrl)
    );
  };

  loginRedirect = () => {
    const { state } = getRedirectStateAfterAuth(location, this.props);
    this.props.history.push({ pathname: "/login", state });
  };

  render() {
    const renderResource = japaneseList.organisms.Header.render;
    const { classes, history } = this.props;
    return (
      <SiteContext.Consumer>
        {(value) => (
          <Root lnln={value.ui === "lnln" ? true : false}>
            {value.ui === "lnln" ? (
              <LinkStyle onClick={this.handleMenuIconClick}>
                <LnlnMenuIcon
                  open={this.state.open}
                  showBadge={!!this.state.notificationNumber}
                />
              </LinkStyle>
            ) : (
              <Container>
                <LinkStyle onClick={this.handleMenuIconClick}>
                  <HeaderIconWapperLeft>
                    <MenuIcon />
                  </HeaderIconWapperLeft>
                </LinkStyle>
                <Logo>
                  <LinkStyle onClick={() => this.props.history.push("/")}>
                    <Img
                      src={imgLogoPath}
                      alt={renderResource.Consumer.Root.Container.Logo.Img.j001}
                    />
                  </LinkStyle>
                </Logo>
                {this.state.login ? (
                  <LinkStyle
                    onClick={() =>
                      this.props.history.push("/notification-list")
                    }
                  >
                    <HeaderIconWapperRight>
                      {this.state.notificationNumber ? (
                        <Badge
                          badgeContent={
                            this.state.notificationNumber >
                            NOTIFICATION_NUMBER_LIMIT
                              ? NOTIFICATION_NUMBER_LIMIT
                              : this.state.notificationNumber
                          }
                          classes={{ badge: classes.badge }}
                        >
                          <Notifications />
                        </Badge>
                      ) : (
                        <Notifications />
                      )}
                    </HeaderIconWapperRight>
                  </LinkStyle>
                ) : (
                  <LinkStyle onClick={() => this.loginRedirect()}>
                    <HeaderIconWapperRight>
                      <LoginButton id="header-sign-in-button">
                        {
                          renderResource.Consumer.Root.Container.DecorativeIcon
                            .LoginButton.j001
                        }
                      </LoginButton>
                    </HeaderIconWapperRight>
                  </LinkStyle>
                )}
              </Container>
            )}
            {this.state.showDuringChattingMessage && <DuringChattingMessage />}
            <LeftMenu
              parent={this}
              open={this.state.open}
              onClose={this.handleMenuIconClick}
              history={history}
            />

            {this.state.login && !this.state.showDuringChattingMessage && (
              <AppointmentReminder />
            )}

            {this.state.login &&
              !this.state.showDuringChattingMessage &&
              (!this.state.open || value.ui !== "lnln") && (
                <OnlineStandbyMessage
                  onTapStandby={() => {
                    if (isWebView())
                      window.location.href = "/telemedicine/standby?lnln_ob=1";
                    else this.props.history.push("/telemedicine/standby");
                  }}
                />
              )}
          </Root>
        )}
      </SiteContext.Consumer>
    );
  }
}

export default withStyles(styles)(withRouter(Header));
