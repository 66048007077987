import React, { Component } from "react";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import { PAYMENT_METHOD } from "../../../Utils/Constant";

const CostItem = styled.div`
  line-height: 1.4;
  display: flex;
`;

const CostName = styled.span`
  font-size: ${(p) => p.theme.font.size.ss};
  color: ${(p) => p.costNameColor || p.theme.font.color.accent};
  white-space: nowrap;
  font-weight: bold;
`;

const Costvalue = styled.span`
  font-size: ${(p) => p.theme.font.size.ss};
  color: ${(p) => p.theme.font.color.default};
  margin-left: 5px;
`;

const Tax = styled.span`
  font-size: 0.6rem;
  color: ${(p) => p.theme.color.fontVariant};
  align-self: flex-end;
`;

class MedicalCost extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { menu, costNameColor } = this.props;
    const costItemResource =
      japaneseList.molecules.MedicalCost.render.div.Container.CostItem;
    const serviceCategoryResource =
      japaneseList.menuServiceCategory[menu.serviceCategory];

    return (
      <React.Fragment>
        {menu.showExamFee && (
          <CostItem>
            <CostName
              costNameColor={costNameColor}
            >{`${serviceCategoryResource.examFeeText} : `}</CostName>
            {menu.showFeeAndTax ? (
              <React.Fragment>
                <Costvalue>
                  <span>{menu.examFeeDescription}</span>
                </Costvalue>
                <Tax>{costItemResource.Tax.j001}</Tax>
              </React.Fragment>
            ) : menu && Boolean(menu.supportPaymentByContractCompany) ? (
              <Costvalue>{costItemResource.CostValue.j003}</Costvalue>
            ) : menu &&
              menu.paymentMethod === PAYMENT_METHOD.BILLING_ENTERPRISE ? (
              <Costvalue>
                {costItemResource.CostValue.j005} <br />{" "}
                {costItemResource.CostValue.j006}
              </Costvalue>
            ) : (
              <Costvalue>{costItemResource.CostValue.j002}</Costvalue>
            )}
          </CostItem>
        )}
      </React.Fragment>
    );
  }
}

export default MedicalCost;
