import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { lighten } from "polished";
import StyledButton from "../../atoms/buttons/Button";
import { isLnln } from "../../../Utils/checkLnln";
import { LnlnOfficeSmallButton } from "../lnlnOffice/LnlnOfficeButtonSmall";
import { japaneseList } from "../../../Resources/japaneseList";

import { getRedirectStateAfterAuth } from "../../../Utils/GetRedirectStateAfterAuth";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.color.secondaryVariant2};
  padding: 15px;
  border-bottom: thin solid ${(p) => p.theme.color.secondary};
`;

const LoginButton = styled(StyledButton)`
  width: 100%;
`;

const SignUpButton = styled(LoginButton)`
  margin-top: 15px;
  background-color: ${(p) => p.theme.button.background.color.cvbutton};
  :active {
    background-color: ${(p) =>
      lighten(0.1, p.theme.button.background.color.cvbutton)};
  }
`;
const LnlnOfficeWrapper = styled.div`
  margin-top: 15px;
  width: 100%;
`;

export const ButtonArea = withRouter((props) => {
  const renderResource = japaneseList.molecules.menu.ButtonArea;

  const handleClickLoginButton = () => {
    const { state } = getRedirectStateAfterAuth(location, props);
    props.history.push({ pathname: "/login", state });
  };

  const handleClickSignUpButton = () => {
    const { state } = getRedirectStateAfterAuth(location, props);
    props.history.push({ pathname: "/auth/signup", state });
  };

  return (
    <Root>
      <LoginButton id="menu-sign-in-button" onClick={handleClickLoginButton}>
        {renderResource.Button.j001}
      </LoginButton>
      <SignUpButton id="menu-sign-up-button" onClick={handleClickSignUpButton}>
        {renderResource.Button.j002}
      </SignUpButton>
      {isLnln() && (
        <LnlnOfficeWrapper>
          <LnlnOfficeSmallButton GAid="menu-to-lnln-office-registration-link" />
        </LnlnOfficeWrapper>
      )}
    </Root>
  );
});
