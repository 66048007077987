import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import dateFns from "date-fns";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import _ from "lodash";
import { Hub } from "aws-amplify";
import FileNote from "../../molecules/patient-upload-file/FileNote";
import { UploadFilePopup } from "../../molecules/popup/UploadFilePopup";
import LoadingScreen from "../../molecules/others/LoadingScreen";
import FileUploadDetail from "../../molecules/fileupload/FileUploadDetail";
import { ReservationUploadFileReview } from "../../organisms/reservation-form/ReservationUploadFileReview";
import { ReservationUploadFileThumbnailList } from "../../organisms/reservation-form/ReservationUploadFileThumbnailList";
import { ReservationUploadFileDetail } from "../../organisms/reservation-form/ReservationUploadFileDetail";
import FullWidthButton from "../../atoms/buttons/ButtonFullWidth";
import { japaneseList } from "../../../Resources/japaneseList";
import {
  APPOINTMENT_STATUS,
  FORM_TYPE,
  PATIENT_ATTACHMENT,
} from "../../../Utils/Constant";
import { toastError } from "../../../Utils/Utility";
import { compressFile, readFile } from "../../../Utils/PatientAttachment";
import GraphQLClient from "../../../Utils/GraphQLClient";
import {
  AttachmentPresignedUrlForPutObject,
  AttachmentPresignedUrlForGetObject,
  PutPatientAttachment,
  DeletePatientAttachment,
  GetApptStatusReservationDayPatientAttachments,
  GenerateGetPresignedUrl,
} from "../../../Utils/GraphQLQueries";

const CANCEL_STATUS = [
  APPOINTMENT_STATUS.CANCELED_BEFORE_APPROVING,
  APPOINTMENT_STATUS.CANCELED_DOCTOR,
  APPOINTMENT_STATUS.CANCELED_PATIENT_FROM_TODAY,
  APPOINTMENT_STATUS.CANCELED_PATIENT_PREVIOUS_DAY,
];

const REJECT_STATUS = [APPOINTMENT_STATUS.REJECTED];

const CANCEL_AND_REJECT_STATUS = [...CANCEL_STATUS, ...REJECT_STATUS];

const { MAX_FILE_SIZE, VALID_FILE_EXTENSION, MAX_DAY_CAN_UPLOAD } =
  PATIENT_ATTACHMENT;

const renderResource =
  japaneseList.organisms.AppointmentDetail.AppointmentFileSharing.render;
const errorsResource =
  japaneseList.organisms.AppointmentDetail.AppointmentFileSharing.errors;

const Container = styled.div`
  padding: 0px 15px;
  line-height: 1.3;
  margin-bottom: 10px;
  margin: 30px 0px 0 0px;
`;

const ButtonContainer = styled.div`
  padding-top: 15px;
`;

const FullWidthButtonCustom = styled(FullWidthButton)`
  height: 50px;
  font-size: 16px;
  padding: 1px 5px;
`;

const NoFileContent = styled.div`
  height: 249px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #999999;
`;

const FileUploadDetailStyle = styled.div`
  padding-top: 30px;
`;

const noteList = [
  renderResource.note.j002,
  renderResource.note.j003,
  renderResource.note.j004,
];

export const AppointmentFileSharing = (props) => {
  const {
    appointment,
    thumbnailList,
    doctorThumbnailList,
    handleDoctorThumbnailList,
    handleThumbnailList,
    showFileSharingError,
    reSelectFile,
  } = props;
  const doctorAttachments =
    appointment.attachment &&
    appointment.attachment.filter((att) => att.status);
  const isApptCancelOrReject =
    appointment && CANCEL_AND_REJECT_STATUS.includes(appointment.status);
  const reservationDay = dateFns.parse(appointment.from);
  const lastDayUploadFile = dateFns.addDays(reservationDay, MAX_DAY_CAN_UPLOAD);
  const isExpiredUploadFile = Date.now() > lastDayUploadFile.getTime();
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const [file, setFile] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [currentFiles, setCurrentFiles] = useState([]);
  const [doctorCurrentFiles, setDoctorCurrentFiles] = useState([]);
  const [formType, setFormType] = useState(FORM_TYPE.NO_FILE);

  useEffect(() => {
    if (
      thumbnailList &&
      thumbnailList.length > 0 &&
      thumbnailList.every((thumbnail) => !_.has(thumbnail, "thumbnailFile"))
    ) {
      loadThumbnailFileList(thumbnailList);
    } else {
      loadCurrentFiles(thumbnailList);
    }
  }, []);

  useEffect(() => {
    if (doctorThumbnailList && doctorThumbnailList.length > 0) {
      loadCurrentDoctorFiles(doctorThumbnailList);
    } else {
      if (appointment && appointment.attachment) {
        const filesOrderByUpdateTime = _.orderBy(
          appointment.attachment,
          ["updatedTime"],
          ["desc"]
        );
        const doctorFilesListAvaiable = filesOrderByUpdateTime.filter((f) => {
          const periodTime = dateFns.parse(f.expiredTime).getTime();
          if (f.status === "available" && periodTime > Date.now()) {
            return true;
          }
          return false;
        });
        if (doctorFilesListAvaiable && doctorFilesListAvaiable.length > 0) {
          loadDoctorFiles(doctorFilesListAvaiable);
        }
      }
    }
  }, []);

  const loadCurrentFiles = (thumbnails) => {
    if (thumbnails && thumbnails.length > 0) {
      setCurrentFiles(thumbnails);
      setFormType(FORM_TYPE.THUMBNAIL_LIST);
    } else {
      setCurrentFiles([]);
      setFormType(FORM_TYPE.NO_FILE);
    }
  };

  const loadCurrentDoctorFiles = (doctorThumbnails) => {
    if (doctorThumbnails && doctorThumbnails.length > 0) {
      setDoctorCurrentFiles(doctorThumbnails);
    } else {
      setDoctorCurrentFiles([]);
    }
  };

  const loadThumbnailFileList = async (thumbnailFiles) => {
    setIsLoading(true);
    setIsShowLoading(true);
    try {
      // Check if display period had passed (last date of next month of examination date), display no file content
      const { data, errors } = await GraphQLClient.query({
        query: GetApptStatusReservationDayPatientAttachments,
        variables: {
          createTime: appointment.createTime,
        },
      });
      if (!errors) {
        const isPatientAttachmentDueDate = isExpiredDay(data.appointment.from);
        if (isPatientAttachmentDueDate) {
          setCurrentFiles([]);
          setFormType(FORM_TYPE.NO_FILE);
          return;
        }
      } else {
        // System error occurs when load file
        toastError(errorsResource.j005);
        setCurrentFiles([]);
        setFormType(FORM_TYPE.NO_FILE);
        return;
      }
      const files = await fetchFiles(thumbnailFiles);
      setCurrentFiles(files);
      handleThumbnailList(files);
      setFormType(FORM_TYPE.THUMBNAIL_LIST);
    } catch (error) {
      // system error occurs when load file
      console.log(error);
      toastError(errorsResource.j005);
      setCurrentFiles([]);
      setFormType(FORM_TYPE.NO_FILE);
    } finally {
      setIsLoading(false);
      setIsShowLoading(false);
    }
  };

  const fetchFiles = async (thumbnailFiles) => {
    const preSignedURLs = await Promise.all(
      thumbnailFiles.map((thumbnailFile) => {
        return GraphQLClient.query({
          query: AttachmentPresignedUrlForGetObject,
          variables: {
            filePath: thumbnailFile.thumbnailFilePath,
            fileName: thumbnailFile.fileName,
          },
        });
      })
    );
    const fileBuffers = await Promise.all(
      preSignedURLs.map((preSignUrl) => {
        return axios.get(
          preSignUrl.data.attachmentPresignedUrlForGetObject.url,
          {
            responseType: "arraybuffer",
          }
        );
      })
    );
    const files = thumbnailFiles.map((thumbnailFile, index) => {
      const filePathDevides =
        thumbnailFile.thumbnailFilePath &&
        thumbnailFile.thumbnailFilePath.split("/");
      const fileIdName = filePathDevides[filePathDevides.length - 1];
      const fileId = fileIdName.split(".").slice(0, -1).join("");
      const extensionFile = fileIdName.split(".").slice(-1)[0];
      const newFile = {
        fileName: thumbnailFile.fileName,
        fileId,
        extensionFile,
        fileType: thumbnailFile.fileType,
        thumbnailFilePath: thumbnailFile.thumbnailFilePath,
        filePath: thumbnailFile.filePath,
        uploadedTime: thumbnailFile.uploadedTime,
        updatedTime: thumbnailFile.updatedTime,
      };
      return {
        ...newFile,
        thumbnailFile: new Blob([fileBuffers[index].data]),
      };
    });
    return files;
  };

  const mapFileData = async (dataLocal, dataFromAPI) => {
    if (dataFromAPI) {
      const latestFiles = dataLocal.filter((local) =>
        dataFromAPI.find((api) => api.filePath === local.filePath)
      );
      const addCandidates = dataFromAPI.filter(
        (api) => !dataLocal.find((local) => local.filePath === api.filePath)
      );
      const updateCandidates = dataFromAPI.filter((local) =>
        dataLocal.find((api) => {
          return (
            api.fileName !== local.fileName && api.filePath === local.filePath
          );
        })
      );
      if (addCandidates && addCandidates.length > 0) {
        const files = await fetchFiles(addCandidates);
        latestFiles.push(...files);
      }
      if (updateCandidates && updateCandidates.length > 0) {
        updateCandidates.forEach((candidate) => {
          const index = latestFiles.findIndex(
            (file1) => file1.filePath === candidate.filePath
          );
          latestFiles[index].fileName = candidate.fileName;
        });
      }
      return latestFiles;
    }
    return dataLocal;
  };

  useEffect(() => {
    if (reSelectFile) {
      handleSelectImage(reSelectFile);
    }
  }, [reSelectFile]);

  const handleChooseFile = useCallback(async (selectFile) => {
    try {
      // Check if display period had passed (last date of next month of examination date), display below error toast
      const { data, errors } = await GraphQLClient.query({
        query: GetApptStatusReservationDayPatientAttachments,
        variables: {
          createTime: appointment.createTime,
        },
      });
      if (!errors) {
        const isPatientAttachmentDueDate = isExpiredDay(data.appointment.from);
        if (isPatientAttachmentDueDate) {
          //  Error if display period had passed (last date of next month of examination date)
          toastError(errorsResource.j007);
          return;
        }
      } else {
        // System error occurs when load file
        toastError(errorsResource.j005);
        return;
      }
      setFile(selectFile);
      setFormType(FORM_TYPE.DETAIL_FILE);
    } catch (error) {
      // System error occurs when load file
      toastError(errorsResource.j005);
    }
  });

  const handleClosePopup = useCallback(() => {
    setIsOpenPopup(false);
  });

  const handleOpenPopup = useCallback(async () => {
    try {
      // Check expired upload file error if photo upload deadline had passed
      const { data, errors } = await GraphQLClient.query({
        query: GetApptStatusReservationDayPatientAttachments,
        variables: {
          createTime: appointment.createTime,
        },
      });
      if (!errors) {
        const videoCallDay = dateFns.parse(data.appointment.from);
        const lastTimeUploadFile = dateFns.addDays(
          videoCallDay,
          MAX_DAY_CAN_UPLOAD
        );
        const checkExpiredUploadFile =
          Date.now() > lastTimeUploadFile.getTime();
        if (checkExpiredUploadFile) {
          toastError(errorsResource.j006);
          return;
        }
        if (REJECT_STATUS.includes(data.appointment.status)) {
          // Error if booking had been rejected
          toastError(errorsResource.j002);
          return;
        }
        if (CANCEL_STATUS.includes(data.appointment.status)) {
          // Error if booking had been canceled
          toastError(errorsResource.j003);
          return;
        }
        if (
          data.appointment &&
          data.appointment.patientAttachments &&
          data.appointment.patientAttachments.length >=
            PATIENT_ATTACHMENT.MAX_FILE_CAN_UPLOADED
        ) {
          // Error can't add more than 5 attachments.
          setIsShowLoading(true);
          const files = await fetchFiles(data.appointment.patientAttachments);
          setCurrentFiles(files);
          handleThumbnailList(files);
          setFormType(FORM_TYPE.THUMBNAIL_LIST);
          toastError(errorsResource.j004);
          setIsShowLoading(false);
          return;
        }
      }

      setIsOpenPopup(true);
    } catch (error) {
      // System errors when update file
      toastError(errorsResource.j005);
    }
  });

  // popup select file
  const handleSelectImage = useCallback(async (selectImage) => {
    const invalidFileType = !VALID_FILE_EXTENSION.includes(selectImage.type);
    const invalidFileSize = selectImage.size > MAX_FILE_SIZE;
    if (invalidFileType || invalidFileSize) {
      showFileSharingError(selectImage);
    } else {
      const fileName = selectImage.name
        ? selectImage.name.split(".").slice(0, -1).join("")
        : "";
      const extensionFile = selectImage.name
        ? selectImage.name.split(".").slice(-1)[0]
        : "";
      const fileType = selectImage.type;
      const buffer = await readFile(selectImage);
      selectImage = new Blob([buffer], { type: fileType });
      const selectFile = {
        fileName,
        extensionFile,
        fileType,
        originalFile: selectImage,
        uploadedTime: Date.now(),
      };
      setFile(selectFile);
      setFormType(FORM_TYPE.REVIEW_FILE);
    }
  });

  const uploadAttachment = async (newFile) => {
    const signedRequest = await GraphQLClient.query({
      query: AttachmentPresignedUrlForPutObject,
      variables: {
        s3FileKey: `patient/origin/${newFile.fileId}.${newFile.extensionFile}`,
        fileType: newFile.fileType,
      },
    });
    const options = {
      headers: {
        "Content-Type": newFile.fileType,
      },
    };
    await axios.put(
      signedRequest.data.attachmentPresignedUrlForPutObject.url,
      newFile.originalFile,
      options
    );
  };

  const reloadPatientAttachments = async () => {
    const { data } = await GraphQLClient.query({
      query: GetApptStatusReservationDayPatientAttachments,
      variables: {
        createTime: appointment.createTime,
      },
    });

    if (data.appointment && !data.appointment.patientAttachments) {
      return;
    }

    const files = await fetchFiles(data.appointment.patientAttachments);
    setCurrentFiles(files);
    handleThumbnailList(files);
  };

  const handleAddNewFileToThumbnailList = async (selectFile) => {
    try {
      setIsLoading(true);
      const id = uuidv4();
      const compressNewFile = await compressFile(selectFile);
      const newFile = {
        fileId: id,
        fileName: selectFile.fileName,
        extensionFile: selectFile.extensionFile,
        fileType: selectFile.fileType,
        uploadedTime: Date.now(),
        updatedTime: Date.now(),
        originalFile: compressNewFile,
      };

      const attachment = {
        fileName: selectFile.fileName,
        fileType: selectFile.fileType,
        filePath: `patient/origin/${newFile.fileId}.${newFile.extensionFile}`,
        thumbnailFilePath: `patient/thumbnail/${newFile.fileId}.${newFile.extensionFile}`,
        uploadedTime: newFile.uploadedTime,
        updatedTime: newFile.updatedTime,
      };

      // Call API upload new file
      await uploadAttachment(newFile);
      const { data, errors } = await GraphQLClient.mutate({
        mutation: PutPatientAttachment,
        variables: {
          appointmentCreateTime: appointment.createTime,
          attachment,
        },
      });
      if (errors) {
        let message;
        switch (errors[0].errorType) {
          case "E100":
            // Error Can't add attachment to cancelled appointment.
            message = errorsResource.j003;
            break;
          case "E101":
            // Error can't add attachment to rejected appointment.
            message = errorsResource.j002;
            break;
          case "E102":
            // Error if photo upload deadline had passed
            message = errorsResource.j006;
            break;
          case "E103":
            // Error if this file had already been deleted
            message = errorsResource.j008;
            break;
          case "E104":
            // Error can't add more than 5 attachments.
            await reloadPatientAttachments();
            message = errorsResource.j004;
            break;
          case "E01":
            // System errors from API
            message = errorsResource.j001;
            break;
          default:
            message = errorsResource.j001;
            break;
        }
        toastError(message);
      } else {
        const dataMapping = await mapFileData(
          currentFiles,
          data.putPatientAttachment
        );
        setCurrentFiles(dataMapping);
        handleThumbnailList(dataMapping);
        handleDoctorThumbnailList(doctorCurrentFiles);
        const toastMessage = `${newFile.fileName}.${newFile.extensionFile}${renderResource.fileAction.j001}`;
        toastActionFile(toastMessage);
      }
      setIsLoading(false);
      setFormType(FORM_TYPE.THUMBNAIL_LIST);
    } catch (err) {
      console.log("Err: ", err);
      setIsLoading(false);
      // System errors when upload file
      toastError(errorsResource.j001);
    }
  };

  const handleUpdateFileThumbnailList = async (id, fileName) => {
    try {
      const { data: appointmentData, errors: appointmentError } =
        await GraphQLClient.query({
          query: GetApptStatusReservationDayPatientAttachments,
          variables: {
            createTime: appointment.createTime,
          },
        });
      if (appointmentError) {
        // System error occurs
        toastError(errorsResource.j005);
        return;
      }
      const isPatientAttachmentDueDate = isExpiredDay(
        appointmentData.appointment.from
      );
      if (isPatientAttachmentDueDate) {
        // Error  If display period had passed (last date of next month of examination date)
        toastError(errorsResource.j007);
        return;
      }

      const index = currentFiles.findIndex((n) => n.fileId === id);
      let fileUpdate = {
        ...currentFiles[index],
        fileName,
        updatedTime: Date.now(),
      };
      const attachment = {
        fileName: fileUpdate.fileName,
        fileType: fileUpdate.fileType,
        filePath: `patient/origin/${fileUpdate.fileId}.${fileUpdate.extensionFile}`,
        thumbnailFilePath: `patient/thumbnail/${fileUpdate.fileId}.${fileUpdate.extensionFile}`,
        uploadedTime: fileUpdate.uploadedTime,
        updatedTime: fileUpdate.updatedTime,
      };

      const { data, errors } = await GraphQLClient.mutate({
        mutation: PutPatientAttachment,
        variables: {
          appointmentCreateTime: appointment.createTime,
          attachment,
        },
      });
      const dataMapping = await mapFileData(
        currentFiles,
        data.putPatientAttachment
      );

      if (errors) {
        let message = errorsResource.j005;
        // Error because Don't exist file on S3
        if (errors.find((e) => e.errorType === "E103")) {
          // Error if this file had already been deleted
          message = errorsResource.j008;
        }
        // System errors when update file
        toastError(message);
        return;
      }
      setCurrentFiles(dataMapping);
      handleThumbnailList(dataMapping);
      handleDoctorThumbnailList(doctorCurrentFiles);
      setFormType(FORM_TYPE.THUMBNAIL_LIST);
      const toastMessage = `${fileUpdate.fileName}.${fileUpdate.extensionFile}${renderResource.fileAction.j002}`;
      toastActionFile(toastMessage);
    } catch (error) {
      // System errors when update file
      toastError(errorsResource.j005);
    }
  };

  const handleDeleteFile = useCallback(async (id) => {
    try {
      const { data: appointmentData, errors: appointmentError } =
        await GraphQLClient.query({
          query: GetApptStatusReservationDayPatientAttachments,
          variables: {
            createTime: appointment.createTime,
          },
        });
      if (appointmentError) {
        // System error occurs
        toastError(errorsResource.j005);
        return;
      }
      const isPatientAttachmentDueDate = isExpiredDay(
        appointmentData.appointment.from
      );
      if (isPatientAttachmentDueDate) {
        // Error if display period had passed (last date of next month of examination date)
        toastError(errorsResource.j007);
        return;
      }
      const fileDelete = currentFiles.find((n) => n.fileId === id);
      const { data, errors } = await GraphQLClient.mutate({
        mutation: DeletePatientAttachment,
        variables: {
          appointmentCreateTime: appointment.createTime,
          filePath: fileDelete.filePath,
        },
      });
      const dataMapping = await mapFileData(
        currentFiles,
        data.deletePatientAttachment
      );

      if (errors) {
        // System errors
        console.log("errors", errors);
        // System errors when delete file
        let message = errorsResource.j005;
        // Error delete file not found
        if (errors.find((e) => e.errorType === "E10")) {
          // Error if this file had already been deleted
          message = errorsResource.j008;
        }
        toastError(message);
        return;
      }
      setCurrentFiles(dataMapping);
      handleThumbnailList(dataMapping);
      handleDoctorThumbnailList(doctorCurrentFiles);
      dataMapping && dataMapping.length > 0
        ? setFormType(FORM_TYPE.THUMBNAIL_LIST)
        : setFormType(FORM_TYPE.NO_FILE);
      const toastMessage = `${fileDelete.fileName}.${fileDelete.extensionFile}${renderResource.fileAction.j003}`;
      toastActionFile(toastMessage);
    } catch (error) {
      // System errors when delete file
      console.log("errors", error);
      toastError(errorsResource.j005);
    }
  });

  const handleBackUploadForm = () => {
    thumbnailList && thumbnailList.length > 0
      ? setFormType(FORM_TYPE.THUMBNAIL_LIST)
      : setFormType(FORM_TYPE.NO_FILE);
  };

  const loadDoctorFiles = async (doctorFilesAvailable) => {
    setIsLoading(true);
    setIsShowLoading(true);
    try {
      const filesAvaiable = await fetchDoctorFilesAvaiable(
        doctorFilesAvailable
      );
      setDoctorCurrentFiles(filesAvaiable);
      handleDoctorThumbnailList(filesAvaiable);
    } catch (error) {
      // system error occurs when load file
      console.log(error);
      toastError(errorsResource.j005);
      setDoctorCurrentFiles([]);
    } finally {
      setIsLoading(false);
      setIsShowLoading(false);
    }
  };

  const fetchDoctorFilesAvaiable = async (doctorFiles) => {
    const preSignedURLs = await Promise.all(
      doctorFiles.map((doctorFile) => {
        return GraphQLClient.mutate({
          mutation: GenerateGetPresignedUrl,
          variables: {
            filePath: doctorFile.thumbnailFilePath
              ? doctorFile.thumbnailFilePath
              : doctorFile.filePath,
          },
        });
      })
    );
    const fileBuffers = await Promise.all(
      preSignedURLs
        .filter((url) => url.data)
        .map((preSignUrl, index) => {
          let url = preSignUrl.data.generateGetPresignedUrl.getSignedRequest;
          const extensionFile = doctorFiles[index].fileName
            .split(".")
            .slice(-1)[0];
          doctorFiles[index].isOriginPdf =
            extensionFile.toLowerCase() === "pdf" && url.includes("/origin/");
          return axios.get(url, {
            responseType: "arraybuffer",
          });
        })
    );
    const files = doctorFiles.map((doctorFile, index) => {
      const filePathDevides = doctorFile.thumbnailFilePath
        ? doctorFile.thumbnailFilePath.split("/")
        : doctorFile.filePath && doctorFile.filePath.split("/");
      const fileIdName = filePathDevides[filePathDevides.length - 1];
      const fileId = fileIdName.split(".").slice(0, -1).join("");
      const extensionFile = fileIdName.split(".").slice(-1)[0];
      const newFile = {
        fileName: doctorFile.fileName.split(".").slice(0, -1).join(""),
        fileId,
        extensionFile,
        fileType: doctorFile.fileType,
        thumbnailFilePath: doctorFile.thumbnailFilePath
          ? doctorFile.thumbnailFilePath
          : doctorFile.filePath,
        filePath: doctorFile.filePath,
        uploadedTime: doctorFile.uploadedTime,
        updatedTime: doctorFile.updatedTime,
        isOriginPdf: doctorFile.isOriginPdf,
      };
      return {
        ...newFile,
        thumbnailFile: new Blob([fileBuffers[index].data]),
      };
    });
    return files;
  };

  const isExpiredDay = (appointmentFrom) => {
    const videoCallDay = dateFns.parse(appointmentFrom);
    const nexMonth = dateFns.addMonths(videoCallDay, 1);
    const lastDayofMonth = dateFns.endOfMonth(nexMonth);
    const patientAttachmentDueDate = dateFns.endOfMonth(lastDayofMonth);
    return Date.now() > patientAttachmentDueDate.getTime();
  };

  const renderForm = () => {
    switch (formType) {
      case FORM_TYPE.NO_FILE:
        return (
          <React.Fragment>
            <NoFileContent>{renderResource.noFileContent.j001}</NoFileContent>
            {isApptCancelOrReject || isExpiredUploadFile ? (
              ""
            ) : (
              <Container>
                <ButtonContainer>
                  <FullWidthButtonCustom onClick={handleOpenPopup}>
                    {renderResource.button.j001}
                  </FullWidthButtonCustom>
                </ButtonContainer>
              </Container>
            )}
            <Container>
              <FileNote title={renderResource.note.j001} noteList={noteList} />
            </Container>
            {doctorAttachments && doctorAttachments.length > 0 && (
              <FileUploadDetailStyle>
                <FileUploadDetail
                  avaiableFiles={doctorCurrentFiles}
                  doctorFiles={appointment.attachment}
                  isShowPlusButon={false}
                />
              </FileUploadDetailStyle>
            )}
          </React.Fragment>
        );
      case FORM_TYPE.THUMBNAIL_LIST:
        return (
          <React.Fragment>
            {currentFiles && currentFiles.length > 0 && (
              <React.Fragment>
                <ReservationUploadFileThumbnailList
                  thumbnailList={currentFiles}
                  handleChooseFile={handleChooseFile}
                  handleOpenPopup={handleOpenPopup}
                  isFromAppointmentDetailPage={true}
                  isApptCancelOrRejectOrExpired={
                    isApptCancelOrReject || isExpiredUploadFile
                  }
                />
              </React.Fragment>
            )}
            <Container>
              <FileNote title={renderResource.note.j001} noteList={noteList} />
            </Container>
            {doctorAttachments && doctorAttachments.length > 0 && (
              <FileUploadDetailStyle>
                <FileUploadDetail
                  avaiableFiles={doctorCurrentFiles}
                  doctorFiles={appointment.attachment}
                  isShowPlusButon={false}
                />
              </FileUploadDetailStyle>
            )}
          </React.Fragment>
        );
      case FORM_TYPE.REVIEW_FILE:
        return (
          <React.Fragment>
            <LoadingScreen isLoading={isLoading} />
            <ReservationUploadFileReview
              file={file}
              handleSelectImage={handleSelectImage}
              handleAddNewFileToThumbnailList={handleAddNewFileToThumbnailList}
              handleBackUploadForm={handleBackUploadForm}
              isFromAppointmentDetailPage={true}
              appointmentCreateTime={appointment.createTime}
            />
          </React.Fragment>
        );
      case FORM_TYPE.DETAIL_FILE:
        return (
          <ReservationUploadFileDetail
            file={file}
            handleUpdateFileThumbnailList={handleUpdateFileThumbnailList}
            handleDeleteFile={handleDeleteFile}
            appointmentFrom={appointment && appointment.from}
            appointmentCreateTime={appointment.createTime}
            isFromAppointmentDetailPage={true}
            setFormType={setFormType}
            handleCurrentFiles={setCurrentFiles}
            currentFiles={currentFiles}
            handleThumbnailList={handleThumbnailList}
          />
        );
      default:
        break;
    }
  };

  const toastActionFile = (actionMessage) => {
    Hub.dispatch("msg", {
      event: "open",
      data: {
        message: actionMessage,
      },
    });
  };

  return (
    <React.Fragment>
      {isShowLoading && <LoadingScreen isLoading={isLoading} />}
      {renderForm()}
      <UploadFilePopup
        isOpen={isOpenPopup}
        handleSelectImage={handleSelectImage}
        handleClosePopup={handleClosePopup}
        isFromDetailAppointment={true}
      />
    </React.Fragment>
  );
};
