import styled from "styled-components";
import { useHistory } from "react-router-dom";

import FontBase from "../../atoms/fonts/FontBase";
import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";
import { japaneseList } from "../../../Resources/japaneseList";

const renderResource = japaneseList.molecules.lnlnOffice;

const SmallButton = styled(ButtonFullWidth)`
  padding: 8px 4px 4px 4px;
  background-color: ${(p) => p.theme.lnlnOffice.skyBlue1};
  color: ${(p) => p.theme.lnlnOffice.darkBlue2};
  border: 1px solid ${(p) => p.theme.lnlnOffice.smokyBlue};
  font-size: 0.9rem;
`;
const SmallFont = styled(FontBase)`
  font-size: 0.7rem;
  font-weight: normal;
  color: ${(p) => p.theme.lnlnOffice.darkBlue2};
`;

interface Props {
  GAid: string;
}

export const LnlnOfficeSmallButton: React.FC<Props> = (props) => {
  const history = useHistory();
  const handleClick: () => void = () => {
    history.push("/enterprise/top");
  };

  const { GAid } = props;

  return (
    <SmallButton onClick={handleClick} id={GAid}>
      {renderResource.button.text}
      <SmallFont>{renderResource.button.small_text}</SmallFont>
    </SmallButton>
  );
};
