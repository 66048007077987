import React from "react";
import ReservationForm from "../organisms/reservation-form/ReservationForm";
import { Auth } from "aws-amplify";
import GraphQLClient from "../../Utils/GraphQLClient";
import {
  Account,
  OrganizationHospitalMenu,
  ReleaseTentativeReservation,
} from "../../Utils/GraphQLQueries";
import AuthenticatedTemplate from "../templates/AuthenticatedTemplate";
import { japaneseList } from "../../Resources/japaneseList";
import * as Sentry from "@sentry/browser";

class ReservationPage extends React.Component {
  constructor(props) {
    super(props);
    this.reserveData =
      this.props.location.state && this.props.location.state.reserveData;
    this.reserveInput =
      this.props.location.state && this.props.location.state.reserveInput;
    if (!this.reserveData) {
      return this.redirectPage();
    }
    this.state = {
      login: false,
      cardStatus: false,
      addressStatus: false,
      account: {},
      isLoading: true,
      checkOrganizationRequireCreditCardRegister: undefined,
    };
    this.getUser();
    this.checkAfterRedirectFromSignIn();
  }

  async componentDidMount() {
    const currentUser = await Auth.currentUserInfo();

    if (currentUser) {
      this.releaseTentativeReservation();
    }
  }

  redirectPage = () => {
    this.props.history.push(`/hospital/${this.props.match.params.hospitalId}`);
    return {
      render() {
        return null;
      },
    };
  };

  async getUser() {
    let user = null;
    try {
      user = await Auth.currentUserInfo();
      user && this.setState({ login: true });
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  }

  async checkAfterRedirectFromSignIn() {
    await this.getCardStatusAndAddress();

    if (this.state.account && this.state.account.organizationId) {
      await this.checkOrganization(this.state.account);
    }

    let locationState = this.props.location.state;
    if (locationState && locationState.from === "SignIn") {
      if (this.state.account && this.state.account.organizationId) {
        if (!this.state.checkOrganizationRequireCreditCardRegister) {
          this.setState({
            cardStatus: true,
          });
        } else {
          this.checkCardStatus(this.reserveData, this.reserveInput);
        }
      } else if (
        this.checkSelfFundedAmount(
          this.state.account?.enterprise?.contractPlans,
          this.reserveData.menu
        )
      ) {
        this.checkCardStatus(this.reserveData, this.reserveInput);
      } else if (this.reserveData.menu.requiredCreditCardRegister) {
        this.checkCardStatus(this.reserveData, this.reserveInput);
      }

      if (this.reserveData.menu.requiredAddressRegister)
        this.checkAddress(this.reserveData, this.reserveInput);
    }
  }

  checkSelfFundedAmount = (contractPlans, menu) => {
    return Boolean(
      contractPlans?.find(
        (contract) =>
          contract.selfFundedAmount &&
          contract.contractPlanId === menu?.contractPlanId
      )?.selfFundedAmount
    );
  };

  getCardStatusAndAddress = async () => {
    const {
      data: { account },
    } = await GraphQLClient.query({ query: Account });
    // TODO: if user is not login, Error occures is organisms/reservation-form/ReservationForm.js if account is null.
    // So, let do not set account and keep account = "{}".
    if (!account) return;

    this.setState({
      account,
    });
    if (account.cardStatus === "registered") {
      this.setState({
        cardStatus: true,
      });
    }
    if (account.address && account.address.postalCode) {
      this.setState({
        addressStatus: true,
      });
    }
  };

  async releaseTentativeReservation(e) {
    await GraphQLClient.mutate({
      mutation: ReleaseTentativeReservation,
    });
  }

  checkCardStatus = (reserveData, reserveInput) => {
    if (!this.state.cardStatus) {
      this.props.history.push({
        pathname: "/reserve/input-paymentcard",
        state: {
          from: "reserve",
          reserveData,
          reserveInput,
        },
      });
    }
  };

  checkAddress = (reserveData, reserveInput) => {
    if (!this.state.addressStatus) {
      this.props.history.push({
        pathname: "/my-page/address/update",
        state: {
          from: "reserve",
          reserveData,
          reserveInput,
        },
      });
    }
  };

  checkOrganization = async (account) => {
    let organizationId_hospitalId = `${account.organizationId}/${this.props.match.params.hospitalId}`;
    let medicalMenu_medicalMethod = `${this.reserveData.menu.medicalMenu}/${this.reserveData.menu.medicalMethod}`;
    if (!organizationId_hospitalId || !medicalMenu_medicalMethod) return;
    const { data, errors } = await GraphQLClient.query({
      query: OrganizationHospitalMenu,
      variables: { organizationId_hospitalId, medicalMenu_medicalMethod },
    });
    if (errors) {
      Sentry.addBreadcrumb({
        message:
          "OrganizationHospitalMenu errorType:" +
          (errors.length ? errors[0].errorType : ""),
        level: Sentry.Severity.Log,
      });
      return;
    }
    this.setState({
      checkOrganizationRequireCreditCardRegister:
        data.organizationHospitalMenu.requireCreditCardRegister,
    });
  };

  checkLoading = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  render() {
    const renderResource =
      japaneseList.organisms.reservationForm.ReservationForm.render;
    const doctorName = this.reserveData && this.reserveData.menu.doctorName;
    const title = !this.reserveData.menu.assignToDoctor
      ? renderResource.Container.Fragment.PrimaryHeading.j001
      : renderResource.Container.Fragment.PrimaryHeading.j002 +
        doctorName +
        renderResource.Container.Fragment.PrimaryHeading.j003;
    const main = (
      <ReservationForm
        {...this.props}
        checkCardStatus={this.checkCardStatus}
        checkAddress={this.checkAddress}
        cardStatus={this.state.cardStatus}
        account={this.state.account}
        addressStatus={this.state.addressStatus}
        login={this.state.login}
        checkLoading={this.checkLoading}
        checkOrganizationRequireCreditCardRegister={
          this.state.checkOrganizationRequireCreditCardRegister
        }
      />
    );
    return (
      <AuthenticatedTemplate
        title={title}
        main={main}
        isLoading={this.state.isLoading}
      />
    );
  }
}

export default ReservationPage;
