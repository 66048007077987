import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import AuthenticatedTemplate from "../templates/AuthenticatedTemplate";
import { SearchFromHospitalHistoriesDetailAndMore } from "../organisms/searchHospital/SearchFromHospitalHistoriesDetailAndMore";
import GraphQLClient from "../../Utils/GraphQLClient";
import { ListAppointmentsByStatus } from "../../Utils/GraphQLQueries";
import { APPOINTMENT_STATUS, FACILITY_TYPE } from "../../Utils/Constant";
import { japaneseList } from "../../Resources/japaneseList";

interface Appointment {
  hospitalId: string;
  status: string;
  hospitalName: string;
  from: string;
  to: string;
  canCreateAppointment: boolean;
}

const renderResource = japaneseList.organisms.AppointmentHistoryPage.render;
const pageSize = 5;
const desc = "DESC";

export const SearchFromHospitalHistoriesPage = () => {
  if (!useLocation().key) {
    useHistory().push("/");
  }
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [nextToken, setNextToken] = useState(null);
  const [continueLoad, setContinueLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(pageSize);
  const total = appointments.length;

  const searchParams = new URLSearchParams(window.location.search.substr(1));
  const facilityType = searchParams.get("type");
  const title =
    facilityType === FACILITY_TYPE.HOSPITAL
      ? renderResource.j008
      : renderResource.j009;

  useEffect(() => {
    loadAppointmentList();
  }, []);

  const loadAppointmentList = async () => {
    const { data, errors } = await GraphQLClient.query({
      query: ListAppointmentsByStatus,
      variables: {
        status: [
          APPOINTMENT_STATUS.EXAM_COMPLETED,
          APPOINTMENT_STATUS.PAYMENT_COMPLETED,
          APPOINTMENT_STATUS.CANCELED_DOCTOR,
          APPOINTMENT_STATUS.CANCELED_PATIENT_PREVIOUS_DAY,
          APPOINTMENT_STATUS.CANCELED_PATIENT_FROM_TODAY,
          APPOINTMENT_STATUS.CANCELED_BEFORE_APPROVING,
        ],
        numberOfItems: pageSize,
        facilityType: [facilityType],
        startToken: nextToken,
        orderBy: desc,
      },
    });
    if (errors) {
      console.error("loadAppointmentHistory errors:", JSON.stringify(errors));
    }
    const newAppointments = [...appointments, ...data.appointments.items];
    setAppointments(newAppointments);
    setNextToken(data.appointments.nextToken);
    if (data.appointments.nextToken || newAppointments.length > pageSize) {
      setContinueLoad(true);
    }
    setIsLoading(false);
  };

  const loadMore = async () => {
    if (nextToken && limit + pageSize > total) {
      await loadAppointmentList();
    }
    setLimit(limit + pageSize);
    if (!nextToken && limit >= total - pageSize) {
      setContinueLoad(false);
    }
  };

  return (
    <AuthenticatedTemplate
      title={title}
      main={
        <SearchFromHospitalHistoriesDetailAndMore
          appointments={appointments.slice(0, limit)}
          continueLoad={continueLoad}
          loadMore={loadMore}
        />
      }
      isLoading={isLoading}
    />
  );
};
