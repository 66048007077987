import { Component } from "react";
import styled from "styled-components";

import MenuItem from "../../molecules/menu/MenuItem";
import ListMenu from "../../atoms/lists/ListMenu";
import { japaneseList } from "../../../Resources/japaneseList";
import { LnlnOfficeSmallButton } from "../lnlnOffice/LnlnOfficeButtonSmall";
import { isLnln } from "../../../Utils/checkLnln";

const LnlnOfficeWrapper = styled.div`
  padding: 15px;
  width: 100%;
`;

class AuthenticatedMenu extends Component {
  render() {
    const renderResource = japaneseList.molecules.menu.AuthenticatedMenu.render;
    return (
      <>
        <ListMenu>
          <MenuItem to="/my-page">
            {renderResource.MenuList.MenuItem.j001}
          </MenuItem>
          <MenuItem to="/terms-of-service">
            {renderResource.MenuList.MenuItem.j002}
          </MenuItem>
          <MenuItem to="/consumer-policy">
            {renderResource.MenuList.MenuItem.j003}
          </MenuItem>
          <MenuItem to="/privacy-policy">
            {renderResource.MenuList.MenuItem.j004}
          </MenuItem>
          <MenuItem to="/application-privacy-policy">
            {renderResource.MenuList.MenuItem.j005}
          </MenuItem>
          <MenuItem
            toExternal={`${process.env.REACT_APP_OKBIZFAQ_URL}`}
            id="menu-authenticated-inquiry-link"
          >
            {renderResource.MenuList.MenuItem.j006}
          </MenuItem>
          <MenuItem to="/sign-out">
            {renderResource.MenuList.MenuItem.j007}
          </MenuItem>
        </ListMenu>
        {isLnln() && (
          <LnlnOfficeWrapper>
            <LnlnOfficeSmallButton GAid="menu-to-lnln-office-registration-link" />
          </LnlnOfficeWrapper>
        )}
      </>
    );
  }
}

export default AuthenticatedMenu;
