import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PlanCard from "./PlanCard";

interface Props {
  account: Account;
  appointmentsAllStatus: any[];
}

interface Account {
  patientId: string;
  cardStatus?: string;
  familyName: string;
  givenName: string;
  familyNameKana: string;
  givenNameKana: string;
  employeeNumber?: string;
  gender: string;
  birthDate: string;
  phoneNumber?: string;
  enterprise?: Enterprise;
}

interface Enterprise {
  enterpriseId: string;
  enterpriseName: string;
  contractPlans: [ContractPlan];
}

interface ContractPlan {
  contractPlanId: string;
  contractPlanName: string;
  plan: Plan;
}

interface Plan {
  planId: string;
  planName: string;
}

export interface MergedPlan extends Plan {
  contractPlanIds: string[];
}

const Container = styled.div`
  padding: 10px 0px;
`;

const ListPlanCard = (props: Props) => {
  const { account, appointmentsAllStatus } = props;
  const contractPlans = account?.enterprise?.contractPlans;
  const mergedPlans = (() => {
    if (contractPlans) {
      const sortedContractPlans =
        sortAscendingOrderContractPlans(contractPlans);
      return groupCotractPlansByPlan(sortedContractPlans);
    }
    return [];
  })();

  return (
    <React.Fragment>
      {mergedPlans && mergedPlans.length > 0 ? (
        <Container>
          {mergedPlans.map((mergedPlan) => {
            return (
              <PlanCard
                key={mergedPlan.planId}
                mergedPlan={mergedPlan}
                planAppointments={filterAppointmentsByPlan(
                  appointmentsAllStatus,
                  mergedPlan.planId
                )}
              />
            );
          })}
        </Container>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

function sortAscendingOrderContractPlans(
  contractPlans: ContractPlan[]
): ContractPlan[] {
  return [...contractPlans].sort((a, b) => {
    return a.plan.planId.localeCompare(b.plan.planId, undefined, {
      numeric: true,
    });
  });
}

function groupCotractPlansByPlan(contractPlans: ContractPlan[]): MergedPlan[] {
  const planMap = new Map<string, MergedPlan>();

  for (const { contractPlanId, plan } of contractPlans) {
    const { planId, planName } = plan;

    if (!planMap.has(planId)) {
      planMap.set(planId, {
        planId,
        planName,
        contractPlanIds: [],
      });
    }

    planMap.get(planId)!.contractPlanIds.push(contractPlanId);
  }

  return Array.from(planMap.values());
}

function filterAppointmentsByPlan(appointments: any[], planId: string): any[] {
  return appointments.filter(
    (appointment) => appointment.plan?.planId === planId
  );
}

export default ListPlanCard;
